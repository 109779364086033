import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translation_en: {
    GLOBAL: {
      // UNIQUE_HEADER_TITLE:['CREATE_HEADER','GRID','SEARCH','POPUP'],
      CREATE_HEADER_BUTTON_TEXT: "Create New",
      PAGINATION_PAGE_NUMBER: "page",
      GRID_ROW_PER_PAGE: "Row Per Page",
      POPUP_SAVE_BUTTON: "save",
      POPUP_CANCEL_BUTTON: "cancel",
      SEARCH_TITLE: "Advanced Search",
      SEARCH_EXPORT_BUTTON: "Export",
      SEARCH_IMPORT_BUTTON: "Import",
      SEARCH_MAP_BUTTON: "Map",
      SEARCH_CREATE_BUTTON: "Create",

      SEARCH_SEARCH_BUTTON: "Search",
      SEARCH_CLEAR_BUTTON: "Clear",
      SEARCH_FROM_DATE: "From date",
      SEARCH_TO_DATE: "To datw",
      SEARCH_REGION: "Region ",
      SEARCH_DISTRICTS: "Districts",
      SEARCH_OWNER_NAME: "Owner Name",
      SEARCH_POINT_STATUS: "Point Status",
      SEARCH_MAX_DISTANCE: "Max Distance (meter)",
      SEARCH_MIN_DISTANCE: "Min Distance (meter)",
      SEARCH_SEND_BUTTON: "Sent",
      SEARCH_RESULT_OF_SEARCH:'Result',
      SEARCH_COMPANY_NAME:'Company Name',

      SEARCH_USERS: "Users",
      SEARCH_HAS_PHONE: "Has Phone",
      SEARCH_HAS_FOLLOWUP: "Has Followup",
      SEARCH_SHOPE_NAME: "Shop Name",
      SEARCH_TO_WORD: "To",
      SEARCH_REQUEST_STATUS: "Request Status",

      MAPS_VIEW_POINT: "View Point",
      MAPS_VIEW_POINT_REQUEST: "View Point Request",
      MAPS_VIEW_VISIT_FOR_POINT: "View Visit For Point",
      MAPS_VIEW_ALL_VISIT_AND_POINT: "View All Visit And Point",

      IMPORT_FORM_TITLE: "Import Point",
      IMPORT_FORM_TITLE_WITH_GEOFENCE: "Import Regions With Geofence",
      IMPORT_POINT_SAMPLE:'Sample',
      IMPORT_REGIONE_SAMPLE:'Sample',



      IMPORT_FORM_MESSAGES: "!This input supports drag and drop file.",
      IMPORT_FORM_UPLOAD_BUTTON: "Upload",
      IMPORT_FORM_CHOOSE_FILE_BUTTON: "Choose File",
      IMPORT_FORM_MESSAGE_NOT_SELECTED_FILE: "No File Selected",

      GRID_NO_DATA: "Empty",
      GRID_NO_RESULT_DATA:'Empty',
      GRID_FOOTER_SAVE_BUTTON: "Save",

      COPYM_MESSAGE_TO_CLIPBOARD:'Coordinates Copied To Clipboard',
      COPY_CORDINATES:'Open Location',

      LINEAR_GRADIANT:'linear gradient',
      LINEAR:'solid',
      CHANGE_BGACKGROUND_COLOR_MODE:'change background color mode ',
      INTERNET_MESSAGE:'You lost Internet connections',
      SEARCH_USER_NAME:'User Name',
      SEARCH_USER_FULL_NAME:'Full user Name',
      SEARCH_COMPANY:'Company',
      SEARCH_DEPARTMENT:'Department',


    },
    DASHBOARD:{
      FROM_DATE:'From Date',
      TO_DATE:'To Date',
      TO:'To',
      SELECT_USER:'Select User',
      SEARCH:'Search',
      VISIT_TIME_PECENTAGE:'Visit Time Percentage',
      ROAD_TIME_PECENTAGE:'Road Time Percentage',
      TOTAL_VISIT_TIME_PECENTAGE:'Total Visit Time Percentage',
      TOTAL_ROAD_TIME_PECENTAGE:'Total Road Time Percentage',

      PRODUCTIVITY_PIE_TITLE:'Total Time productivity percentage for Specific Period',
      PRODUCTIVITY_PIE_SUBTITLE:'This graphic shows the ratio of the road time to the time of the visit, and the total ratio of both the road and the visit',
      TOTAL_ROAD_TIME_PECENTAGE:'Total Road Time Percentage ',
      TOTAL_VISIT_TIME_PECENTAGE:'Total Visit Time Percentage',
    },
    USERS: {
      UNIQUE_HEADER_TITLE:'Grid content',
      ID: "ID",
      FULL_NAME: "Full Name",
      USER_NAME: "User Name",
      PHONE: "Phone",
      DEPARTMENT: "Department",
      ACTIVE: "Active",
      ACTIONS: "Actions",
      COMPANY:'Company',
      IS_DEMO:'IsDemo',

    


      UNIQUE_HEADER_TITLE:'form Add content',
      HEADER_TITLE: "User List",
      USER_ADD_FORM: "Add User",
      USER_EDIT_FORM: "Edit User Data",
      FORM_FULLNAME: "Full Name",
      FORM_PHONE: "Phone",
      FORM_USERNAME: "UserName",
      FORM_DEPARTMENT: "Department",
      FORM_PASSWORD: "Password",
      FORM_COMPANY:'Company',
      FORM_SUBMIT:'Submit',
      FORM_SELECT_DEPARTMENT:'Select Department',
      FORM_SELECT_COMPANY:'Select Company',
      USER_ROLES: "User Roles",

      USER_GROUP_TITLE: "User Groups",
      USER_GROUP_NAME:'Group',
      USER_GROUP_ACCESS_GROUP:'User Access Group',
      Form_assign_shift_to:'To',
      FORM_ATTENDANCE_TYPE:'Attendance Type',
      USER_ASSIGN_SHIFT_TYPE:'Assign Shifts To User Attendance Type',
      FILTERSEARCH_HEADER:'Add Shifts',
      FORM_ID:'ID',
      FORM_STRAT_TIME:'Format Time',
      FORM_END_TIME:'End Time',
      FORM_REQUIRED_TIME:'Required Time',
      FORM_ATTENDANCE_TYPE:'Attendance Type',
      FORM_ACTIVATION_TIME:'Activation Time',
      FORM_SHIFTS:'Shifts',
      FORMVIEW_SEARCH_SAVE_BUTTON:'Save'
      


    },
    VISITS: {
      ID: "ID",
      NAME: "Point Name",
      PHONE: "Phone",
      USER: "User",
      START_END_DATE: "Start End Date",
      IS_CANCELL: "Is Cancell",
      REASON: "Cancelation Reason",
      NOTE: "Not",
      FOLLOWUPS: "Followups",
      ACTIONS: "Actions",
      VISITED_BY: "Visited By",
      FORMVIEW_TITLE: "Visit Info",
      FORMVIEW_TABLE_NAME: "Name",
      FORMVIEW_TABLE_VALUE: "Value",
      FORMVIEW_TABLE_THERES_NO_VALUES: "There aren't any survey values",
      FORMVIEW_ADD_VISIT_FOLLOWUP: "Add Visits Follow-UP",
      FORMVIEW_SEARCH_SAVE_BUTTON: "Save",
      FORMVIEW_SEARCH_FOLLOWUP_NOTE: "Follow-UP Note...",
      FORMVIEW_SEARCH_STATUS: "Status",
      FORMVIEW_SEARCH_LOADING_BUTTON: "loading...",

      FORMVIEW_GRID_ID: "form view ID",
      FORMVIEW_GRID_STATUS: "form view Name",
      FORMVIEW_GRID_NOTE: "form view Note",
    },
    POINTS: {
      ID: "ID",
      DISTRICT: "District",
      REGION: "Region",
      NAME: "Name",
      OWNER_DETAILS: "Owner Details",
      OWNER_TYPE: "Owner Type",
      OWNER_NAME: "Owner Name",
      ACTIONS: "Actions",
      PHONE: "Phone",
      FORMvIEW_FIELD_NAME: "Field Name",
      FORMVIEW_POINT_NAME: "Point Name",
      FORMVIEW_NEAR_BY: "Near By",
      FORMVIEW_LOCATION_CATEGORY: "Location Category",
      FORMVIEW_CLASSIFICATION: "Classification",
      FORMVIEW_CATEGORY: "Category",
      FORMVIEW_POINT_TYPE: "Point Type",
      FORMVIEW_REGION: "Region",
      FORMVIEW_LATITUDE: "Latitude",
      FORMVIEW_LONGITUDE: "Longitude",
      FORMVIEW_HAS_DELIVERY: "Has Delivery",
      FORMVIEW_IMAGE: "Image",
      FORMVIEW_MESSAGE_NO_IMAGE: "No Image Selected",

      FORMADD_TITLE: "Add Point",
      FORMADD_EXTERNAL_CUSTOMER_ID: "External Customer ID",
      FORMADD_SELECT_OWNER_TYPE: "Select Owner Type",
      FROMADD_SELECT_REGION: "Select Region",
      FROMADD_SELECT_CATEGORY: "Select Category",
      FROMADD_SELECT_LOCATION_CATEGORY: "Select Location Category",
      FORMADD_SELECT_CLASS: "Select Class",
      FROMADD_STATUS: "Type(Status)",
      FROMADD_SELECT_STATUS: "Select Type (status)",
      FROMADD_SUBTITLE_MESSAGE: "Select a point location on the map",
      FROMADD_SUBMIT_BUTTON: "Submit",
    },
    POINTS_REQUESTS: {
      ID: "ID",
      LOCATION: "Location",
      USER: "User",
      NAME: "Name",
      OWNER_DETAILS: "Owner details",
      REQUEST_DATA: "Request data",
      STATUS: "Status",
      ACTIONS: "Actions",

      FORMVIEW_FIELD_NAME: "Field name",
      FORMVIEW_REQUEST_CHANGES: "Request changes",
      FORMVIEW_ORIGINAL_POINT: "Original point",
      FORMVIEW_APPROVE_OR_REJECT: "Approve/reject",
      OWNER_TYPE: "Owner Type",
      PHONE: "Phone",
      FORMVIEW_NEAR_BY: "Near By",
      FORMVIEW_LOCATION_CATEGORY: "Location Category",
      FORMVIEW_CLASSIFICATION: "Classification",
      FORMVIEW_CATEGORY: "Category",
      FORMVIEW_IMAGE: "Image",
      FORMVIEW_IMAGES:'images',
      FORMVIEW_MESSAGE_SELECT_REQUEST_STATUS: "Select Request Status",
      FORMVIEW_MESSAGE_APPROVE: "Approve",
      FORMVIEW_MESSAGE_REJECT: "Reject",
      FORMVIEW_MESSAGE_SELECT_ALL: "Select All",

       FORMVIEW_NAME:"Name",
       FORMVIEW_OWNER_NAME:'Owner Name',
       FORMVIEW_REGION:"Region",
       FORMVIEW_LATITUDE:'Latitude',
       FORMVIEW_LONGITUDE:'Longitude',
       FORMVIEW_HAS_DELIVERY:'Has Delivery',

       FORMVIEW_title:'point Request for Changes'
      //  FORMVIEW_IMAGE:'Image',

    },
    REGION: {
      ID: "ID",
      DISTRICT: "District",
      REGION: "Region",
      ACTIONS: "Actions",
      FORMADD_TITLE: "Add Region",
      FORMUPDATE_TITLE: "Edit Region",
      FORM_NAME: "Name",
      FORM_DISTRICT: "District",
      FORM_MESSAGE: "Draw a geofence",
      FORM_SUB_MESSAGE:
        "Draw a geofence on the map by clicking on the map to create markers, then you can adjust the shape by dragging the markers",
      FORM_SUBMIT_FORM: "Submit",
      FORM_SELECT_DISTRICT: "Select District",
      ALL_REGIONS:"ALL Regions"
    },
    SURVEY: {
      HEADER_TITLE: "Surveys  list",
      ID: "ID",
      NAME: "Name",
      POINT_STATUS: "Point Status",
      POINT_NAME: "Point Name",
      ACTIONS: "Actions",

      FORMVIEW_TITLE: "View Survey",
      FORMVIEW_CONDITION: "Condition",
      FORMVIEW_TYPE: "Type",
      FORMVIEW_IS_MANDATORY: "Is Mandatory",
      FORMVIEW_COLLAPSED_BUTTON: "Collapse",
      FORMVIEW_EXPAND_BUTTON: "CollapsE",

      FORMADD_TITLE: "Create Survey",
      FORMUPDATE_TITLE: "Update Survey",
      FORMADD_NAME: "Name",
      FORMADD_SELECT_POINT: "Select Point",
      FORMADD_POINT_STATUS: "Point Status",

      BUILDSURVEY_TITLE: "Build Surveys",
      BUILDSURVEY_LIST_TITLE: "Survey Fields list",
      BUILDSURVEY_GRID_ID: "ID",
      BUILDSURVEY_GRID_NAME: "Name",
      BUILDSURVEY_GRID_TYPE: "Type",
      BUILDSURVEY_GRID_LIST_TYPE: "List Type",
      BUILDSURVEY_GRID_IS_MANDATORY: "Is Mandatory",
      BUILDSURVEY_GRID_HAS_CONDITION: "Has Condition",
      BUILDSURVEY_GRID_PARENT_FIELD_ID: "Parent Field ID",
      BUILDSURVEY_GRID_UNDER_CONDITIONS: "Ununder Conditions",
      BUILDSURVEY_GRID_DEFAULT_VALUE: "Default Value",
      BUILDSURVEY_GRID_ACTIONS: "Actions",

      BUILDSURVEY_FORMADD_TITLE: "Create Survey Field",
      BUILDSURVEY_FORMUPDATE_TITLE: "UPDATE Survey Field",
      BUILDSURVEY_FORMADD_NAME: "Name",
      BUILDSURVEY_FORMADD_DEFAULT_NAME: "Default Name",
      BUILDSURVEY_FORMADD_FIELD_TYPE: "Field Type",
      BUILDSURVEY_FORMADD_FIELD_LIST: "Field List",
      BUILDSURVEY_FORMADD_PARENT_SURVEY_FIELD: "Parent Survey Field",
      BUILDSURVEY_FORMADD_CONDITION_VALUE: "Condition Value",
      BUILDSURVEY_FORMADD_SELECT_CONDITION_VALUE: "Select Condition Value",
      BUILDSURVEY_FORMADD_IS_MADNDATORY: "Is Mandatory",
      BUILDSURVEY_FORMADD_HAS_CONDITION: "Has Condition",
      BUILDSURVEY_FORMADD_IS_MULTIPLE_SELECT: "Has Multiple Select",
    },
    JOURNEYS: {
      ID: "ID",
      USERNAME: "User Name",
      PHONE: "Phone",
      COUNT: "Count",
      TODAY_PLAN: "Day Plan",
      ACTIONS: "Actions",
      START_DATE:'Start Date',
      END_DATE:'End Date',
      JOURNEY_TYPE:'Journey Type',
      FULL_NAME:'Full Name',


      USERDAILYPLAN_TITLE: "User Daily Plans List",
      USERDAILYPLAN_ID: "ID",
      USERDAILYPLAN_PLAN_DATE: "Plan Date",
      USERDAILYPLAN_TOTAL: "Total",
      USERDAILYPLAN_ACTIONS: "Actions",

      USERDAILYPLAN_FORMADD_TITLE: "Select Daily Plan",
      USERDAILYPLAN_FORMADD_SELECT_DATE: "Select Date",
      USERDAILYPLAN_FORMADD_DISTIRCTS: "DistrictS",
      USERDAILYPLAN_FORMADD_REGIONS: "Regions",
      USERDAILYPLAN_FORMADD_POINTS: "Points",

      USERDAILYPLAN_FORMADD_SELECTED_REGIONS: "Selected Regions",
      USERDAILYPLAN_FORMADD_TOTAL_SELECTED_POINTS: "Total selected points",
      USERDAILYPLAN_FORMADD_GRAND_TOTAL_POINT: "Grand Total Points",

      CREATE_JP:'Create jp',
      VIEW_JP:'View jp',
      JP_TYPE:"Journey Type",
      JP_DAILY:"Daily",
      JP_WEEKLY:"Weekly",
      JP_SELECT_MANDOOB:'Select Mandoob',
      JP_DATE :'Journey Date',
      JP_REPEAT:'Repeat',
      JP_REPEAT_UNTIL:'Repeat until',
      JP_FROM_TO_DATE:'From TO Date',
      JP_SUNDAY:'Sunday',
      JP_MONDAY:'Monday',
      JP_TUESDAY:'Tuesday',
      JP_WEDNESDAY:'Wednesday',
      JP_THURSDAY:'Thursday',
      JP_FRIDAY:'Friday',
      JP_SATURDAY:'Saturday',
      JP_DAILY_DATE:' JP Date',
      JP_DAILY_TAB:'Daily Plane',
      SAVEALL:'Save All',
      CANCEL:'Cancel',
      FROMDATE:'from Date',
      TODATE:'To Date',
      TO:"To",
      JP_SHOW_DETIALS:'Show Details of Journey',
      JP_SEARCH_POINTS:'Search',
      NO_MANDOOBEEN:'No data',
      PLANSFOR_SELECTED_DATE:'Plan details for today',
      MUST_SELECT_PLAN:'Must Select Plan',
      SELECTED_SHIFT_Details:'Shift Details',
      JOURNY_TYPE:'Journey Type',
    },
    ROLES: {
      TITLE: "Role List",
      ID: "ID",
      ROLE_NAME: "Role Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    DEPARTMENTS: {
      TITLE: "Departments List",
      ID: "ID",
      DEPARTMENT_NAME: "Department Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    DISTIRCTS: {
      TITLE: "Districts List",
      ID: "ID",
      DISTIRCT_NAME: "District Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    FIELD_LISTS: {
      TITLE: "fieldList List",
      ID: "ID",
      FIELD_LISTS_NAME: "FieldList Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",

      FIELD_LIST_VALUE_TITLE: "FieldListValue List",
      FIELD_LIST_VALUE_ID: "ID",
      FIELD_LIST_VALUE_NAME: "FieldListValue Name",
      FIELD_LIST_VALUE_ACTIONS: "Actions",
      FIELD_LIST_VALUE_FORMADD_NAME: "Name",
    },
    POINT_STATUS: {
      TITLE: "Points status List",
      ID: "ID",
      POINT_STATUS_NAME: "PointType Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    VISIT_REPORT: {
      ID: "ID",
      NAME: "Name",
      PHONE: "Phone",
      OWNER_NAME: "Owner Name",
      REGION: "Region",
      DISTRICT: "District",
      BY: "By",
      START_DATE: "Start Date",
      START_D: "Start D",
      END_DATE: "End Date",
      END_D: "End D",
      ROAD_TIME: "Road Time",
      VISIT_TIME: "Visit Time",
      ACTIONS: "Actions",
      POINTDETAILS:'Point Details',
    },
    ATTENDANCE_REPORT:{
      ID: "ID",
      ROAD_TIME: "Road Time",
      START_DATE: "Start Date",
      END_DATE: "End Date", 
      VISIT_TIME: "Visit Time",
      ROAD_CALC:'Road CaLC',
      VISIT_CALC:'Visit CaLC',
      OVERRIDE_ROAD_TIME: "Override Road Time",
      OVERRIDE_VISIT_TIME: "Override Visit Time",

      ATTENDANCE_TOTAL_SUMMARY:' Summary',
      ATTENDANCE_ROAD_TIME:'Road Time',
      ATTENDANCE_VISIT_TIME:'Visit Time',
      ATTENDANCE_TOTAL_WORK_TIME:'Total Work Time',
      ATTENDANCE_USER_WORKING_HOURS_PER_DAY:'Working Hours / Day',
      ATTENDANCE_USER_WORKING_HOURS_PER_DAY_CUSTOME:'User WH/D',
      ATTENDANCE_USER_NAME:'User Name',

      ATTENDANCE_OVERRIDE:'Overridden',
      ATTENDANCE_NOT_OVERRIDE:'Not Overridden',
      VALID:'valid',
      NOT_VALID:'unValid',

      NUMBER_OF_Friday:'The Number of Day of Friday',
      NUMBER_OF_DAY:'Number Of Days',

    },
    FOLLOWUP_REPORT:{
       ID: "ID",
       SALESMAN:'Salesman',
       CUSTOMER:'Customer',
       PHONE:'Phone',
       START_DATE:'Start Date',
       STATUS:'Status',
       NOTE:'Note',
    },
    SIDEMENU:{
        DASHBOARD:'Dashboard',
        USERS:'Users',
        VISITS:'Visits',
        POINTS:'Points',
        POINTS_REQUESTS:'Points Requests',
        REGIONS:'Regions',
        SURVEYS:'Surveys',
        JOURNEYS:'Journeys',
        MASTERS:'Masters',
        ROLES:'Roles',
        DEPARTMENTS:'Departments',
        DISTRICTS:'Districts',
        FIELD_LISTS:'Field Lists',
        POINT_STATUS:'Point Status',
        REPORT:'Report',
        VISITS_REPORT:'Visits Report',
        ATTENDANCE_REPORT:'Attendance Report',
        FLOWUP_REPORT:'Flows Report',
        COMPANIES:'Companies',
        NOTIFICATIONS:'Notifications',
        GROUPS:'Groups',
        SETTINGS:'Settings',
        PROFILE:'Profile',
        FOLLOW_UP_STATUS:'Follow Up Status',

        EDIT_JOURNEYS:'Edit Journey',
        ADD_JOURNEYS:'Add Journey',
        SIGN_OUT:'Sign Out',
        ATTENDANCE_SUMMERY_REPORT:'Attendance Summery',
        LANGUAGE:'Language'
    },
    APPBAR:{
      MY_PROFILE:'My Profile',
      SIGN_OUT:'Sign Out',
      SIGN_IN:'Sign In',
      
      TOOLTIP_LANGUAGE:'Language',
      TOOLTIP_NOTIFICATION:'Notification',
      TOOLTIP_FULL_SCREEN:'Full Screen',
      TOOLTIP_USERS:'Users',
      TOOLTIP_ANALYTICS_DASHBOARD:'Analytics Dashboard',
      TOOLTIP_EXISTS_FULL_SCREEN:'Exists from  full screen',
      TOOLTIP_COMPANY:'Company',
      TOOLTIP_SELECT_A_COMPANY:"Select a Company",
      SEARCH_PUPOP_TITLE:'Search for a Company'

    },
    NOTIFICATIONS:{
      TITLE:'Notifications',
      DISMISSION_ALL_NOTIFICATIONS: 'Dismission All',
      NO_NOTIFICATIONS: 'Thers Is No Notification Right Now',
      HEADER_TITLE:'Notification lists',

      FORM_EDIT_TITLE:'Edit Notification',
      FORM_ADD_TITLE:'Add Notification',
      FORM_NOTIFICATION_TITLE:'Title',
      FORM_NOTIFICATION_BODY:'Body',
      FORM_NOTIFICATION_DATA:'Data',
      FORM_NOTIFICATION_NOTIFICATION_TYPE:'Notification Type',
      FORM_NOTIFICATION_USERS:'Users',
      FORM_NOTIFICATION_GROUPS:'Groups',
      FORM_NOTIFICATION_SENT_PUSH_NOTIFICATION:'Send Push Notification',

      GRID_ID:'ID',
      GRID_TITLE:'Title',
      GRID_BODY:'Body',
      GRID_SEND_PUSH_NOTIFICATION:'Sent Push Notification',
      GRID_NOTIFICATION_TYPE:'Notification Type',
      GRID_USER_COUNT:'User Count',
      GRID_SEND_SUCCESSFULLY:'Notification Status',
      GRID_ACTIONS:'Actions',

      FORM_NOTIFICATION_SELECT_NOTIFICATION_TYPE:'Select Notification Type',
      FORM_NOTIFICATION_SELECT_GROUPS:'Select Groups',
      FORM_NOTIFICATION_SELECT_USERS:'Select Users',
      
      NOTIFICATIONS_USERS_TITLE:'List of users to ',
      NOTIFICATIONS_USERS_CANCEL:'Close',
      TOOLTIP_SEND_PUSH_NOTIFICATION:'will  be notified upon receipt of the notification',
      TOOLTIP_NOT_SEND_PUSH_NOTIFICATION:'will not be notified upon receipt of the notification'

    },
    SETTINGS:{
      TITLE:'Theme Settings',
      SIDEMENU_LAYOUT:'SideMenu Layout',
      SIDEMENU_ICONMODE:'Icon Mode',
      SIDEMENU_DIRECTION:'Directions Mode',
      THEME_LAYOUT:'Theme Layout',
      THEME_LAYOUT_MAIN_PALETTE:'Main Palette',
      THEME_LAYOUT_TOOLBAR_PALETTE:'Toolbar Palette',
      THEME_LAYOUT_SIDEMENU_PALETTE:'SideMenu Palette',
      THEME_LAYOUT_GRID_PALETTE:'Grid Palette',
      DASHBOARD_LAYOUT:'Dashboard Layout',
      SEARCH_FILTER_LAYOUT:'Filter buttons layout',
      SEARCH_FILTER_SEARCH_BUTTON:'Search button',
      SEARCH_FILTER_CLEAR_BUTTON:'Clear button',
      SEARCH_FILTER_EXPORT_BUTTON:'Export button',
      SEARCH_FILTER_IMPORT_BUTTON:'Import button',
      SEARCH_FILTER_CREATE_BUTTON:'Create button',
      SEARCH_FILTER_MAP_BUTTON:'Map button',
      SEARCH_FILTER_TELEGRAM_BUTTON:'Telegram button',
      SEARCH_FILTER_SAVE_BUTTON:'Save button',
      ERROR_PAGE_BUTTON:'go Back',
      DASHBOARD_VISIT_TIME_COLOR:"Visit Time",
      DASHBOARD_ROAD_TIME_COLOR:"Road Time",
      



      MANDOOBEEN_SETTINGS:'Mandoobean-Settings',
      SETTINGS_UPDATE_BUTTON:'Update',
   

      SETTINGS_ADD_NEW_KEY_SECTION_AND_VALUE:'Add New Section key',
      SETTINGS_edit_NEW_KEY_SECTION_AND_VALUE:'edit  Section key',
      SETTINGS_delete_NEW_KEY_SECTION_AND_VALUE:'delete  Section key',
      SETTINGS_edit_value_of_SECTION_key:'Edit value of section key',


      SETTINGS_ADD_NEW_KEY_SECTION_NAME:'Section Key',

      SETTINGS_ADD_NEW_KEY_AND_VALUE:'Add New key',
      SETTINGS_ADD_NEW_KEY_NAME:'Key',

      SETTINGS_Edit_KEY_AND_VALUE:'Edit key',
      SETTINGS_DELETE_KEY_AND_VALUE:'Delete key',
      SETTINGS_Edit_VALUE_Key:'Edit value',




      SETTINGS_ADD_NEW_VALUE_NAME_EN:'English value',
      SETTINGS_ADD_NEW_VALUE_NAME_AR:'Arabic value',
      SETTINGS_ADD_NEW_VALUE_NAME:'value',

      SETTINGS_ADD_BUTTON:'Add',
      SETTINGS_edit_BUTTON:'Edit',
      SETTINGS_delete_BUTTON:'Delete',

      
      GRID_LAYOUT:'Grid Layout',
      GRID_MODE:'Vertical or Horizontal',
      GRID_CEHCK_LABEL:'Mode',


      SELECT_FILTER_BY:'Filter Type',
      SEARCH_BOX:'Key name',
 
      NO_DATA:'No data'


    },
    COMPANY:{
      ID:"ID",
      IMAGE:"Image",
      COMPANY_NAME:"Company Name",
      NUMBER_OF_USERS:"Number of users",
      PRICE:"Price",
      PERIOD_NAME:"Period name",
      DISCOUNT_PERCENTAGE:"Discount percentage",
      ACTIONS:"Actions",


      FORMADD_TITLE:'Add Company' ,
      FORMEDIT_TITLE:'Edit Company' ,
      FORM_COMPANY_NAME:'Company Name',
      FORM_NUMBER_OF_USERS:'Number of users',
      FORM_TRIAL_PERIOD:'Trial period',
      FORM_PRICE:'Price',
      FORM_NUMBER_OF_ALLOW_DEMO_USER_PER_MONTH:'Number of Allow Demo Users per Month',
      FORM_BILLING_FREQUENCY:'Billing frequency',
      FORM_PARENT_COMPANY:'Parent Company',
      FORM_SELECT_COMPANY:'Select Company',
      FORM_SELECT_ABILLING_FREQUENCY:'Select a Billing Frequency',
      FORM_ALLOW_DEMO:'Allow Demo',
      FORM_ACTIVE_STATUS:'Active status',
      FORM_IS_REAL_COMPANY:'Is real company',
      FORM_LOGO:'Logo',
      FORM_NO_LOGO_SELECTED:'No Logo Selected',
      FORM_SUBMIT:'Submit',
    },
    SETTINGS_SECTIONS:{
      GLOBAL:'Global',
      GLOBAL_SETTINGS:'Global Settings',
      
      DASHBOARD:"Dashboard",
      DASHBOARD_SETTINGS:'Dashboard Settings',

      SIDEMENU:"Side Menu",
      SIDEMENU_SETTINGS:'SideMenu Settings',

      APPBAR:'AppBar',
      APPBAR_SETTINGS:'AppBar Settings',

      TOOLBAR:"Toolbar",
      TOOLBAR_SETTINGS:'Toolbar Settings',

      USERS:'Users',
      USERS_SETTINGS:'Users Settings',

      VISITS:'Visits',
      VISITS_SETTINGS:'Visits Settings',

      POINTS:'Points',
      POINTS_SETTINGS:'Points Settings',

      POINTS_REQUESTS:'Points Requests',
      POINTS_REQUESTS_SETTINGS:'Points Requests Settings',

      REGION:'Region',
      REGION_SETTINGS:'Region Settings',

      SURVEY:'Survey',
      SURVEY_SETTINGS:'Survey Settings',

      JOURNEYS:'Journeys',
      JOURNEYS_SETTINGS:'Journeys Settings',

      ROLES:'Roles',
      ROLES_SETTINGS:'Roles Settings',

      DEPARTMENTS:'Departments',
      DEPARTMENTS_SETTINGS:'Departments Settings',


      DISTIRCTS:'Districts',
      DISTIRCTS_SETTINGS:'Districts Settings',

      FIELD_LISTS:'Field  Lists',
      FIELD_LISTS_SETTINGS:'Field  Lists Settings',

      POINT_STATUS:'Points Status',
      POINT_STATUS_SETTINGS:'Points Status Settings',

      VISIT_REPORT:'Visits Report',
      VISIT_REPORT_SETTINGS:'Visits Report Settings',

      ATTENDANCE_REPORT:'Attendance Report',
      ATTENDANCE_REPORT_SETTINGS:'Attendance Report Settings',

      FOLLOWUP_REPORT:'Followup Report',
      FOLLOWUP_REPORT_SETTINGS:'Followup Report Settings',

      NOTIFICATIONS:'Notifications',
      NOTIFICATIONS_SETTINGS:'Notifications Settings',

      SHIFTS:'Shifts',
      SHIFTS_SETTINGS:'Shifts Settings',

      PROFILE:'Profile',
      PROFILE_SETTINGS:'Profile Settings',

      // LOGIN_PAGE:'Login',
      // LOGIN_PAGE_SETTINGS:"Login Settings",

      GROUPS:'Groups',
      GROUPS_SETTINGS:'Groups Settings',

      SETTINGS:'Settings',
      SETTINGS_SETTINGS:'Settings Settings',

    

      TOOLTIP:'Tooltip',
      TOOLTIP_SETTINGS:'Tooltip Settings',

      COMPANY:'Companys',
      COMPANY_SETTINGS:'Companys Settings',
            
      FOLLOW_UP_STATUS:'Follow up Status',
      FOLLOW_UP_STATUS_SETTINGS:'Follow up Status Settings',
      
      ERROR_PAGE:'Error Pages',
      ERROR_PAGE_SETTINGS:'Error Pages Settings',

      SWEATALERT_MESSAGES:'Sweatalerts',
      SWEATALERT_MESSAGES_SETTINGS:'Sweatalerts Settings',

      SETTINGS_OTHERS:'OTHER',
      SETTINGS_OTHERS_SETTINGS:'Additional Settings',
      
      LANGUAGES:'languages',
      LANGUAGES_SETTINGS:'languages Settings',
    },
    SWEATALERT_MESSAGES:{
      WARNNING_MESSAGE:'Warnnings',
      ADD_NEW_KEY_AND_VALUES_message:'You Must Fill all Fields',
      ADD_NEW_KEY_AND_VALUES_EXISTS:'this key is already exists',
      WARRNING_BUTTON:'OK',
      IMAGE_NOT_FOUND:'You Must select Image',

      USER_ID_NOT_FOUND:'User id not found',

    },
    LOGIN_PAGE:{
      SINGIN_TITLE:'Sign In',
      SIGNIN_USERNAME:'User Name',
      SIGNIN_PASSWORD:'Password',
      SIGNIN_USERNAME_ERROR_SHORT:'Username is short',
      SIGNIN_USERNAME_ERROR_BLANCK:"user name field can't be blank",
      SIGNIN_PASSWORD_ERROR_SHORT:'Password must be more than 3 char',
      SIGNIN_PASSWORD_ERROR_BLANCK:"password field can't be blank",
      SIGNIN_BUTTON:'Sign In',
      SIGNIN_OR_CONTINU_WITH:'Or Continue With ',
      WELLCOME_MESSAGE:'Welcome',
      SUBWELLCOME_MESSAGE:'to Mandoobeen',
      INFO_MESSAGE_1:`Platform helps you manage your company representitives by providing state of art features which gives you control and unlock your visibility to know all about the team movements and performance`,
      // INFO_MESSAGE_2:'dashboards full of beautiful and rich modules. Join us and start',
      // INFO_MESSAGE_3:'building your system today.',
      FOOTER_MESSAGE:"More than 17k people joined us, it's your turn"

    },
    ERROR_PAGE:{
       ERROR_TITLE_404_ERROR:'Error',
       ERROR_TITLE_404:'Not Found Page',
       
       ERROR_TITLE_500_ERROR:'Error',
       ERROR_TITLE_500:'internal server error',

       ERROR_TITLE_401_ERROR:'Error',
       ERROR_TITLE_401:'unauthorized access error',
       Go_BACK_BUTTON:'go back',
    },
    GROUPS:{
      TITLE:'Groups Lists',
      ID: "ID",
      NAME: 'Name',
      COLOR:'Color',
      ACTIONS:'Actions',
      FORM_EDIT_TITLE:'Edit Group',
      FORM_ADD_TITLE:'Add Group',
      FORM_NAME:'name',
      FORM_COLOR:'color',
      FORM_SUBMIT:'Submit',


    },
    PROFILE:{
      USER_TITLE:'Edit Profile',
      FULL_NAME:'Full Name',
      USERNAME:'Name',
      PHONE:'Phone',
      PASSWORD:'Password',
      UPDATE_BUTTON:'Update Info',
    },
    TOOLBAR:{
      SEARCH_PUPOP_TITLE:'Search for a Company'
    },
    FOLLOW_UP_STATUS:{
      ID:'ID',
      NAME:'NAME',
      ACTIONS:'ACTIONS',
      FORMADD_NAME:"Name",
    },
    SHIFTS:{
      ID:'ID',
      SHIFTS_STARTS_TIME:'Start Time',
      SHIFTS_END_TIME:'End Time',
      SHIFTS_REQUIRED_TIME:'Required Time',
      SHIFTS_DAY_DIFFERENCE:'Day Difference',
      ACTIONS:'Actions',
      HEADER_TITLE:'Shifts List',

      FORM_START_WORKING_HOURS:'Start Work Time',
      FORM_END_WORKING_HOURS:'End Work Time',
      FORM_REQUIRED_WORKING_HOURS:'Required Work Hours',
    },
    TOOLTIP:{
      CREATE:'Create',
      EXPORT:'Export',
      MAP:'MAP',
      SEND_TELEGRAM:'Send Telegram',
      IMPORT:'Import',
      VIEW:'View',
      COPY:'Copy',
      PAST:'Paste'
    },
    LANGUAGES:{
      title:'Languages List',
      gird_name:'Name',
      gird_code:'Code',
      grid_rtl:'Text Direction',
      grid_image:'Image',
      grid_id:'ID',
      grid_actions:'Actions',
      form_name:'Name',
      form_code:'Code',
      form_rtl:'Text Direction',
      form_image:'Image',
    }

  },
  translation_ar: {
    GLOBAL: {
      CREATE_HEADER_BUTTON_TEXT: "أنشئ جديد",
      PAGINATION_PAGE_NUMBER: "الصفحة",
      GRID_ROW_PER_PAGE: "ٌالاسطر في كل صفحة",
      POPUP_SAVE_BUTTON: "حفظ",
      POPUP_CANCEL_BUTTON: "ألغاء",
      SEARCH_TITLE: "بحث متقدم",
      SEARCH_EXPORT_BUTTON: "تصدير",
      SEARCH_IMPORT_BUTTON: "أستيراد",
      SEARCH_MAP_BUTTON: "خريطة",
      SEARCH_CREATE_BUTTON: "انشاء",

      SEARCH_SEARCH_BUTTON: "بحث",
      SEARCH_CLEAR_BUTTON: "مسح",
      SEARCH_FROM_DATE: "من تاريخ",
      SEARCH_TO_DATE: "الى تاريخ",
      SEARCH_REGION: "منطقة",
      SEARCH_DISTRICTS: "قطاع",
      SEARCH_OWNER_NAME: "اسم المدير",
      SEARCH_POINT_STATUS: "حالة النقطة",
      SEARCH_MAX_DISTANCE: "اكبر مسافة (متر)",
      SEARCH_MIN_DISTANCE: "اقل مسافة (متر)",
      SEARCH_SEND_BUTTON: "ارسال",
      SEARCH_RESULT_OF_SEARCH:'نتيجة',
      SEARCH_COMPANY_NAME:'أسم الشركة',


      SEARCH_USERS: "المستخدمين",
      SEARCH_HAS_PHONE: "لديه هاتف",
      SEARCH_HAS_FOLLOWUP: "لديه متابعة",
      SEARCH_SHOPE_NAME: "اسم المحل",
      SEARCH_TO_WORD: "الى",
      SEARCH_REQUEST_STATUS: "حالة الطلب",

      MAPS_VIEW_POINT: "اظهار النقطة",
      MAPS_VIEW_POINT_REQUEST: "عرض طلب النقطة",
      MAPS_VIEW_VISIT_FOR_POINT: "عرض الزيارة لهذه النقطة",
      MAPS_VIEW_ALL_VISIT_AND_POINT: "اضهار جميع الزيارات والنقاط",

      IMPORT_FORM_TITLE: "استيراد نقطة",
      IMPORT_FORM_TITLE_WITH_GEOFENCE: "استيراد المناطق مع Geofence",
      IMPORT_FORM_MESSAGES: "!هذا المدخل يدعم سحب وافلات الملفات",
      IMPORT_FORM_UPLOAD_BUTTON: "تحميل",
      IMPORT_FORM_CHOOSE_FILE_BUTTON: "اختر ملف",
      IMPORT_FORM_MESSAGE_NOT_SELECTED_FILE: "لم يتم اختيار اي ملف",

      IMPORT_POINT_SAMPLE:'مثال',
      IMPORT_REGIONE_SAMPLE:'مثال',


      GRID_NO_DATA: " فارغ",
      GRID_NO_RESULT_DATA:'فارغ',
      GRID_FOOTER_SAVE_BUTTON: "حفظ",
      COPYM_MESSAGE_TO_CLIPBOARD:'تم نسخ الإحداثيات إلى الحافظة',
      COPY_CORDINATES:'فتح الموقع',
      LINEAR_GRADIANT:'لون متدرج',
      LINEAR:'لون واحد',
      CHANGE_BGACKGROUND_COLOR_MODE:'تغيير وضع لون الخلفية ',
      INTERNET_MESSAGE:'لقد فقدت الاتصال بالإنترنت',
      SEARCH_USER_NAME:'اسم الامستخد',
      SEARCH_USER_FULL_NAME:'اسم المستخدم كامل',
      SEARCH_COMPANY:'الشركة',
      SEARCH_DEPARTMENT:'القسم'
    },
    DASHBOARD:{
      FROM_DATE:'من تاريخ',
      TO_DATE:'الى تاريخ',
      TO:'الى',
      SELECT_USER:'اختر المستخدم',
      SEARCH:'بحث',
      VISIT_TIME_PECENTAGE:'النسبة المئوية لوقت الزيارة',
      ROAD_TIME_PECENTAGE:'النسبة المئوية لوقت الطريق',

      TOTAL_VISIT_TIME_PECENTAGE:'النسبة المئوية الإجمالية لوقت الزيارة',
      TOTAL_ROAD_TIME_PECENTAGE:'النسبة المئوية الإجمالية لوقت الطريق',
      PRODUCTIVITY_PIE_TITLE:'مجموع نسب انتاجة الوقت للفترة المحددة',
      PRODUCTIVITY_PIE_SUBTITLE:'يظهر هذا الرسم نسبة وقت الطريق الى وقت الزيارة , والنسبة الكلية لكل من الطريق والزيارة',
      TOTAL_ROAD_TIME_PECENTAGE:'النسبة المئوية الاجمالية لوقت الطريق',
      TOTAL_VISIT_TIME_PECENTAGE:'النسبة المئوية الاجمالية لوقت الزيارة',

    },
    USERS: {
      UNIQUE_HEADER_TITLE:'محتويات الجدول',
      ID: "ID",
      FULL_NAME: "الاسم كامل",
      USER_NAME: "أسم المستخدم",
      PHONE: "الهاتف",
      DEPARTMENT: "القسم",
      ACTIVE: "الحالة",
      ACTIONS: "الوظائف",
      COMPANY:'الشركة',
      IS_DEMO:' تجريبي',


      UNIQUE_HEADER_TITLE:'محتويات قائمة الاضافة',
      HEADER_TITLE: "قائمة المستخدمين",
      USER_ADD_FORM: "اضافة مستخدم",
      USER_EDIT_FORM: "تعديل بيانات المستخدم",
      FORM_FULLNAME: "الاسم  كامل",
      FORM_PHONE: "الهاتف",
      FORM_USERNAME: "أسم المستخدم",
      FORM_DEPARTMENT: "القسم",
      FORM_PASSWORD: "الرقم السري",
      FORM_COMPANY:'شركة',
      FORM_SUBMIT:'ارسال',
      FORM_SELECT_DEPARTMENT:'اختر قسم',
      FORM_SELECT_COMPANY:'اختر شركة',

      USER_ROLES: "صلاحيات المستخدم",

      USER_GROUP_TITLE: "مجاميع المستخدم",
      USER_GROUP_NAME:'المجموعة',
      USER_GROUP_ACCESS_GROUP:'مجموعات وصول المستخدمين',
      Form_assign_shift_to:'الى',
      FORM_ATTENDANCE_TYPE:'نوع الحضور',
      USER_ASSIGN_SHIFT_TYPE:'اسناد الوجبات الى نوع حضور المستخدم',
      FILTERSEARCH_HEADER:'اضافة وجبات',
      FORM_ID:'ID',
      FORM_STRAT_TIME:'وقت البدء',
      FORM_END_TIME:'وقت الانتهاء',
      FORM_REQUIRED_TIME:'الوقت المطلوب',
      FORM_ATTENDANCE_TYPE:'نوع الحضور',
      FORM_ACTIVATION_TIME:'وقت التنشيط',
      FORM_SHIFTS:'الوجبات',
      FORMVIEW_SEARCH_SAVE_BUTTON:'حفظ'

    },
    VISITS: {
      ID: "ID",
      NAME: "اسم النقطة",
      PHONE: "الهاتف",
      USER: "المستخدم",
      START_END_DATE: "بداية ونهاية الوقت",
      IS_CANCELL: "هل أُلغيت",
      REASON: "سبب الالغاء",
      NOTE: "ملاحظة",
      FOLLOWUPS: "متابعة",
      ACTIONS: "الوظائف",
      VISITED_BY: "زيارة بواسطت",

      FORMVIEW_TITLE: "معلومات الزيارة",
      FORMVIEW_TABLE_NAME: "الاسم",
      FORMVIEW_TABLE_VALUE: "القيمة",
      FORMVIEW_TABLE_THERES_NO_VALUES: "لا توجد أي قيم للاستبيان",

      FORMVIEW_ADD_VISIT_FOLLOWUP: "اضافة متابعة للزوار",
      FORMVIEW_SEARCH_SAVE_BUTTON: "حفظ",
      FORMVIEW_SEARCH_FOLLOWUP_NOTE: "ملاحظات المتابعة...",
      FORMVIEW_SEARCH_STATUS: "الحالة",
      FORMVIEW_SEARCH_LOADING_BUTTON: "تحميل...",

      FORMVIEW_GRID_ID: "عرض ID",
      FORMVIEW_GRID_STATUS: "عرض الحالة",
      FORMVIEW_GRID_NOTE: "عرض الملاحظات",
    },
    POINTS: {
      ID: "ID",
      DISTRICT: "قطاع",
      REGION: "منطقة",
      NAME: "الاسم",
      OWNER_DETAILS: "تفاصيل المالك",
      OWNER_TYPE: "نوع المالك",
      OWNER_NAME: "اسم المالك",
      ACTIONS: "الوظائف",
      PHONE: "هاتف",
      FORMvIEW_FIELD_NAME: "اسم الحقل",
      FORMVIEW_POINT_NAME: "اسم النقطة",
      FORMVIEW_NEAR_BY: "قريب على",
      FORMVIEW_LOCATION_CATEGORY: "فئة الموقع",
      FORMVIEW_CLASSIFICATION: "التصنيف",
      FORMVIEW_CATEGORY: "فئة",
      FORMVIEW_POINT_TYPE: "نوع النقطة",
      FORMVIEW_REGION: "المنطقة",
      FORMVIEW_LATITUDE: "خط العرض",
      FORMVIEW_LONGITUDE: "خط الطول",
      FORMVIEW_HAS_DELIVERY: "لديه توصيل",
      FORMVIEW_IMAGE: "صورة",
      FORMVIEW_MESSAGE_NO_IMAGE: "لم يتم اختيار صورة",

      FORMADD_TITLE: "اضافة نقطة",
      FORMADD_EXTERNAL_CUSTOMER_ID: "معرف زبون خارجي",
      FORMADD_SELECT_OWNER_TYPE: "اختر نوع المالك",
      FROMADD_SELECT_REGION: "اختر المنطقة",
      FROMADD_SELECT_CATEGORY: "اختر الفئة",
      FROMADD_SELECT_LOCATION_CATEGORY: "اختر فئة الموقع",
      FORMADD_SELECT_CLASS: "ختر الصنف",
      FROMADD_STATUS: "النوع(الحالة)",
      FROMADD_SELECT_STATUS: "اختر النوع (الحالة)",
      FROMADD_SUBTITLE_MESSAGE: "اختر موقع النقطة على الخريطة",
      FROMADD_SUBMIT_BUTTON: "ارسال",
    },
    POINTS_REQUESTS: {
      ID: "ID",
      LOCATION: "الموقع",
      USER: "المستخدم",
      NAME: "الاسم",
      OWNER_DETAILS: "معلومات المالك",
      REQUEST_DATA: "طلب البيانات",
      STATUS: "الحالة",
      ACTIONS: "الوظائف",
      FORMVIEW_FIELD_NAME: "أسم الحقل",
      FORMVIEW_REQUEST_CHANGES: "طلب التغييرات",
      FORMVIEW_ORIGINAL_POINT: "النقطة الأصلية",
      FORMVIEW_APPROVE_OR_REJECT: "الموافقة / الرفض",
      OWNER_TYPE: "نوع المالك",
      PHONE: "هاتف",
      FORMVIEW_NEAR_BY: "قريب على",
      FORMVIEW_LOCATION_CATEGORY: "فئة الموقع",
      FORMVIEW_CLASSIFICATION: "تصنيف",
      FORMVIEW_CATEGORY: "فئة",
      FORMVIEW_IMAGE: "صورة",
      FORMVIEW_IMAGES:'صور',
      FORMVIEW_MESSAGE_SELECT_REQUEST_STATUS: "حدد حالة الطلب",
      FORMVIEW_MESSAGE_APPROVE: "موافقة",
      FORMVIEW_MESSAGE_REJECT: "رفض",
      FORMVIEW_MESSAGE_SELECT_ALL: "اختيار الكل",

      FORMVIEW_NAME:"الاسم",
      FORMVIEW_OWNER_NAME:'أسم المالك',
      FORMVIEW_REGION:"المقاطعة",
      FORMVIEW_LATITUDE:'خط العرض',
      FORMVIEW_LONGITUDE:'خط الطول',
      FORMVIEW_HAS_DELIVERY:'لديه التسلم',
      // FORMVIEW_IMAGE:'صورة',
      FORMVIEW_title:'طلب نقطة للتغييرات'

    },
    REGION: {
      ID: "ID",
      DISTRICT: "قطاع",
      REGION: "منطقة",
      ACTIONS: "الوظائف",

      FORMADD_TITLE: "اضافة منطقة",
      FORMUPDATE_TITLE: "تعديل منطقة",
      FORM_NAME: "الاسم",
      FORM_DISTRICT: "مقاطعة",
      FORM_MESSAGE: "ارسم سياجًا جغرافيًا",
      FORM_SUB_MESSAGE:
        "ارسم سياجًا جغرافيًا على الخريطة من خلال النقر على الخريطة لإنشاء محددات ، ثم يمكنك ضبط الشكل عن طريق سحب العلامات",
      FORM_SUBMIT_FORM: "ارسال",
      FORM_SELECT_DISTRICT: "اختر مقاطعة",
      ALL_REGIONS:'جميع المناطق'
    },
    SURVEY: {
      HEADER_TITLE: "قائمة الاستطلاعات",
      ID: "ID",
      NAME: "الاسم",
      POINT_STATUS: "حالة النقطة",
      POINT_NAME: "اسم النقطة",
      ACTIONS: "الوظائف",

      FORMVIEW_TITLE: "عرض الاستطلاع",
      FORMVIEW_CONDITION: "شرط",
      FORMVIEW_TYPE: "نوع",
      FORMVIEW_IS_MANDATORY: "إلزامي",
      FORMVIEW_COLLAPSED_BUTTON: "تصغير",
      FORMVIEW_EXPAND_BUTTON: "تكبير",

      FORMADD_TITLE: "انشاء استطلاع",
      FORMUPDATE_TITLE: "تحديث الاستطلاع",
      FORMADD_NAME: "الاسم",
      FORMADD_SELECT_POINT: "اختيار نقطة",
      FORMADD_POINT_STATUS: "حالة النقطة",

      BUILDSURVEY_TITLE: "بناء استطلاع",
      BUILDSURVEY_LIST_TITLE: "لائحة حقول الاستطلاع",
      BUILDSURVEY_GRID_ID: "ID",
      BUILDSURVEY_GRID_NAME: "الاسم",
      BUILDSURVEY_GRID_TYPE: "النوع",
      BUILDSURVEY_GRID_LIST_TYPE: "نوع اللأحة",
      BUILDSURVEY_GRID_IS_MANDATORY: "ألزامي",
      BUILDSURVEY_GRID_HAS_CONDITION: "لديه شرط",
      BUILDSURVEY_GRID_PARENT_FIELD_ID: "معرف حقل الاب ",
      BUILDSURVEY_GRID_UNDER_CONDITIONS: "شروط غير خاضعة",
      BUILDSURVEY_GRID_DEFAULT_VALUE: "القيمة الافتراضية",
      BUILDSURVEY_GRID_ACTIONS: "الوظائف",

      BUILDSURVEY_FORMADD_TITLE: "انشاء حقول استطلاع",
      BUILDSURVEY_FORMUPDATE_TITLE: "تحديث حقول الاستطلاع",
      BUILDSURVEY_FORMADD_NAME: "الاسم",
      BUILDSURVEY_FORMADD_DEFAULT_NAME: "الاسم الافتراضي",
      BUILDSURVEY_FORMADD_FIELD_TYPE: "نوع الحقل",
      BUILDSURVEY_FORMADD_FIELD_LIST: "لائحة الحقول",
      BUILDSURVEY_FORMADD_PARENT_SURVEY_FIELD: "حقل استطلاع الاب",
      BUILDSURVEY_FORMADD_CONDITION_VALUE: "قيمة الشرط",
      BUILDSURVEY_FORMADD_SELECT_CONDITION_VALUE: "اختيار قيم الشرط",
      BUILDSURVEY_FORMADD_IS_MADNDATORY: "ألزامي",
      BUILDSURVEY_FORMADD_HAS_CONDITION: "لديه شرط",
      BUILDSURVEY_FORMADD_IS_MULTIPLE_SELECT: "لديه خيارات متعددة",
    },
    JOURNEYS: {
      ID: "ID",
      USERNAME: "اسم المستخدم",
      PHONE: "الهاتف",
      COUNT: "عدد",
      TODAY_PLAN: "خطة اليوم",
      ACTIONS: "الوظائف",
      START_DATE:'تاريخ البدء',
      END_DATE:'تاريخ الانتهاء',
      JOURNEY_TYPE:'نوع الخطة',

      USERDAILYPLAN_TITLE: "لائحة خطط المستخدم اليومية",
      USERDAILYPLAN_ID: "ID",
      USERDAILYPLAN_PLAN_DATE: "تاريخ الخطة",
      USERDAILYPLAN_TOTAL: "المجموع",
      USERDAILYPLAN_ACTIONS: "الوظائف",

      USERDAILYPLAN_FORMADD_TITLE: "حدد الخطة اليومية ",
      USERDAILYPLAN_FORMADD_SELECT_DATE: "اختر التاريخ",
      USERDAILYPLAN_FORMADD_DISTIRCTS: "المقاطعات",
      USERDAILYPLAN_FORMADD_REGIONS: "المناطق",
      USERDAILYPLAN_FORMADD_POINTS: "النقاط",

      USERDAILYPLAN_FORMADD_SELECTED_REGIONS: "المناطق المحددة",
      USERDAILYPLAN_FORMADD_TOTAL_SELECTED_POINTS: "مجموع النقاط المحددة",
      USERDAILYPLAN_FORMADD_GRAND_TOTAL_POINT: "أجمالي النقاط",

      CREATE_JP:'انشاء خطة للرحلة',
      VIEW_JP:'عرض خطة الرحلة',
      JP_TYPE:"نوع الرحلة",
      JP_DAILY:"يومي",
      JP_WEEKLY:"اسبوعي",
      JP_SELECT_MANDOOB:'اختر مندوب',
      JP_DATE :'تاريخ الرحلة',
      JP_REPEAT:'كرر',
      JP_REPEAT_UNTIL:'كرر حتى',
      JP_FROM_TO_DATE:'من الى تاريخ',
      JP_SUNDAY:'الاحد',
      JP_MONDAY:'الاثنين',
      JP_TUESDAY:'الثلاثاء',
      JP_WEDNESDAY:'الاربعاء',
      JP_THURSDAY:'الخميس',
      JP_FRIDAY:'الجمعة',
      JP_SATURDAY:'السبت',
      JP_DAILY_DATE:' تاريخ خطة الرحلة',
      JP_DAILY_TAB:'خطة يومية',
      SAVEALL:'حفظ الجميع',
      CANCEL:'الغاء',
      FROMDATE:'من تاريخ',
      TODATE:'الى  تاريخ',
      TO:"الى",
      JP_SHOW_DETIALS:'عرض تفاصيل الرحلة',
      JP_SEARCH_POINTS:'بحث',
      NO_MANDOOBEEN:'لا توجد بيانات',
      PLANSFOR_SELECTED_DATE:'تفاصيل الخطة لهذا اليوم',
      MUST_SELECT_PLAN:'يجب اختيار  الخطة ',
      SELECTED_SHIFT_Details:'تفاصيل الوجبة',
      JOURNY_TYPE:'نوع الخطة',
    },
    ROLES: {
      TITLE: "لائحة الصلاحيات",
      ID: "ID",
      ROLE_NAME: "اسم الصلاحية",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    DEPARTMENTS: {
      TITLE: "لائحة الاقسام",
      ID: "ID",
      DEPARTMENT_NAME: "اسم القسم",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    DISTIRCTS: {
      TITLE: "لائحة المقاطعات ",
      ID: "ID",
      DISTIRCT_NAME: "اسم المقاطعة",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    FIELD_LISTS: {
      TITLE: "لوائح الحقول",
      ID: "ID",
      FIELD_LISTS_NAME: "اسم لائحة الحقل",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",

      FIELD_LIST_VALUE_TITLE: "قيم قائنمة الحقول",
      FIELD_LIST_VALUE_ID: "ID",
      FIELD_LIST_VALUE_NAME: "اسم قيمة قائمة الحقول",
      FIELD_LIST_VALUE_ACTIONS: "الوظائف",
      FIELD_LIST_VALUE_FORMADD_NAME: "الاسم",
    },
    POINT_STATUS: {
      TITLE: "قائمة حالة النقاط",
      ID: "ID",
      POINT_STATUS_NAME: "اسم نوع النقطة",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    VISIT_REPORT: {
      ID: "ID",
      NAME: "الاسم",
      PHONE: "الهاتف",
      OWNER_NAME: "اسم المالك",
      REGION: "المنطقة",
      DISTRICT: "المقاطعة",
      BY: "بواسطت",
      START_DATE: "تاريخ البدء",
      START_D: "ابدأ د",
      END_DATE: "تاريخ الانهاء",
      END_D: "انهاء د",
      ROAD_TIME: "وقت  الطريق",
      VISIT_TIME: "وقت الزيارة",
      ACTIONS: "الوظائف",
      POINTDETAILS:'تفاصيل النقطة',

    },
    ATTENDANCE_REPORT:{
      ID: "ID",
      ROAD_TIME: "الوقت على الطريق",
      START_DATE: "تاريخ البدء",
      END_DATE: "تاريخ الانهاء", 
      VISIT_TIME: "وقت الزيارة",
      ROAD_CALC:'حساب الطريق',
      VISIT_CALC:'حساب الزيارة',
      OVERRIDE_ROAD_TIME: "تجاوز وقت الطريق",
      OVERRIDE_VISIT_TIME: "تجاوز وقت الزيارة",
      
      ATTENDANCE_TOTAL_SUMMARY:' الملخص',
      ATTENDANCE_ROAD_TIME:'الوقت على الطريق',
      ATTENDANCE_VISIT_TIME:'وقت الزيارة',
      ATTENDANCE_TOTAL_WORK_TIME:'إجمالي وقت العمل',
      ATTENDANCE_USER_WORKING_HOURS_PER_DAY:'ساعات عمل / اليوم',
      ATTENDANCE_USER_WORKING_HOURS_PER_DAY_CUSTOME:'المستخدم WH/D',
      ATTENDANCE_USER_NAME:'اسم المستخدم',


      ATTENDANCE_OVERRIDE:'اهمل',
      ATTENDANCE_NOT_OVERRIDE:'لا تهمل',
      VALID:'معتمد',
      NOT_VALID:'غير معتمد',
      NUMBER_OF_Friday:'عدد ايام الجمعة للفترة المحددة',
      NUMBER_OF_DAY:'عدد الايام',
    },
    FOLLOWUP_REPORT:{
      ID: "ID",
      SALESMAN:'بائع',
      CUSTOMER:'العميل',
      PHONE:'هاتف',
      START_DATE:'تاريخ البدء',
      STATUS:'الحالة',
      NOTE:'ملاحظة',
    },
    SIDEMENU:{
      DASHBOARD:'لوحة التحكم',
      USERS:'المستخدمين',
      VISITS:'الزيارات',
      POINTS:'النقاط',
      POINTS_REQUESTS:'طلبات النقاط',
      REGIONS:'المناطق',
      SURVEYS:'الاستطلاعات',
      JOURNEYS:'المسارات',
      MASTERS:'الرئيسية',
      ROLES:'الصلاحيات',
      DEPARTMENTS:'الاقسام',
      DISTRICTS:'المقاطعات',
      FIELD_LISTS:'قائمة الحقول',
      POINT_STATUS:'حالة النقاط',
      REPORT:'التقارير',
      VISITS_REPORT:'تقرير الزيارات',
      ATTENDANCE_REPORT:'تقرير الحضور',
      FLOWUP_REPORT:'تقرير المتابعة',
      COMPANIES:'الشركات',
      NOTIFICATIONS:'الأشعارات',
      GROUPS:'المجاميع',
      SETTINGS:'الاعدادات',
      SIGN_OUT:'تسجيل خروج',
      PROFILE:'الملف الشخصي',
      FOLLOW_UP_STATUS:'حالات المتابعة',
      EDIT_JOURNEYS:'تعديل الرحلة',
      ADD_JOURNEYS:'اضافة رحلة',
      ATTENDANCE_SUMMERY_REPORT:'ملخص الحضور',
      LANGUAGE:'اللغات'

      


    },
    APPBAR:{
      MY_PROFILE:'ملفي الشخصي',
      SIGN_OUT:'تسجيل الخروج',
      SIGN_IN:'تسجيل دخول',
      
      TOOLTIP_LANGUAGE:'اللغة',
      TOOLTIP_NOTIFICATION:'الاشعارات',
      TOOLTIP_FULL_SCREEN:'وضع الشاشة الكاملة',
      TOOLTIP_EXISTS_FULL_SCREEN:'خروج من وضع الشاشة الكاملة',

      TOOLTIP_USERS:'المستخدمين',
      TOOLTIP_ANALYTICS_DASHBOARD:'لوحة التحليلات',
      TOOLTIP_COMPANY:'الشركة',
      TOOLTIP_SELECT_A_COMPANY:"اختر شركة",
      SEARCH_PUPOP_TITLE:"ابحث عن شركة"
    },
    NOTIFICATIONS:{
      TITLE:'الاشعارات',
      DISMISSION_ALL_NOTIFICATIONS: 'حذف الكل',
      NO_NOTIFICATIONS: 'لا توجد اشعارات في الوقت حاليا',
      HEADER_TITLE:'لائحة الاشعارات',
      
      FORM_EDIT_TITLE:'تعديل بيانات الاشعار',
      FORM_ADD_TITLE:'اضافة اشعار',
      FORM_NOTIFICATION_TITLE:'العنوان',
      FORM_NOTIFICATION_BODY:'المحتوى',
      FORM_NOTIFICATION_DATA:'البيانات',
      FORM_NOTIFICATION_NOTIFICATION_TYPE:'نوع الاشعار',
      FORM_NOTIFICATION_USERS:'المستخدمين',
      FORM_NOTIFICATION_GROUPS:'المجاميع',
      FORM_NOTIFICATION_SENT_PUSH_NOTIFICATION:'ارسال اشعار تنبيهي',

      GRID_ID:'ID',
      GRID_TITLE:'العنوان',
      GRID_BODY:'المحتوى',
      GRID_SEND_PUSH_NOTIFICATION:'أرسال اشعار تنبيهي',
      GRID_NOTIFICATION_TYPE:'نوع الاشعار',
      GRID_USER_COUNT:'عدد المستخدمين',
      GRID_SEND_SUCCESSFULLY:'حالة الاشعار',
      GRID_ACTIONS:'الوظائف',

      FORM_NOTIFICATION_SELECT_NOTIFICATION_TYPE:'اختر نوع الاشعار',
      FORM_NOTIFICATION_SELECT_GROUPS:'اختر المجاميع',
      FORM_NOTIFICATION_SELECT_USERS:'اختر المستخدمين',

      NOTIFICATIONS_USERS_TITLE:'قائمة المستخدمين لاشعار ',
      NOTIFICATIONS_USERS_CANCEL:'اغلاق',
      TOOLTIP_SEND_PUSH_NOTIFICATION:'سيتم تنبيه المندوب عند استلام الاشعار',
      TOOLTIP_NOT_SEND_PUSH_NOTIFICATION:' لن يتم  تنبيه المندوب عند استلام الاشعار'



    },
    SETTINGS:{
    TITLE:'اعدادات التنسيق',
    SIDEMENU_LAYOUT:'واجهة القائمة الجانبية',
    SIDEMENU_ICONMODE:'وضع الايقونات',
    SIDEMENU_DIRECTION:'وضع الاتجاهات',
    THEME_LAYOUT:'واجهة التنسيق',
    THEME_LAYOUT_MAIN_PALETTE:'اللوحة الرئيسية',
    THEME_LAYOUT_TOOLBAR_PALETTE:'لوحة شريط الادوات',
    THEME_LAYOUT_SIDEMENU_PALETTE:'لوحة القائمة الجانبية',
    THEME_LAYOUT_GRID_PALETTE:'لوحة الجداول',
    DASHBOARD_LAYOUT:'واجهت لوحة التحكم',

    SEARCH_FILTER_LAYOUT:'تخطيط أزرار التصفية',
    SEARCH_FILTER_SEARCH_BUTTON:'زر البحث',
    SEARCH_FILTER_CLEAR_BUTTON:'زر المحو',
    SEARCH_FILTER_EXPORT_BUTTON:'زر التصدير',
    SEARCH_FILTER_IMPORT_BUTTON:'زر الاستيراد',
    SEARCH_FILTER_CREATE_BUTTON:'زر الانشاء',
    SEARCH_FILTER_MAP_BUTTON:'زر الخريطة',
    SEARCH_FILTER_TELEGRAM_BUTTON:'زر التلكرام',
    SEARCH_FILTER_SAVE_BUTTON:'زر الحفظ',
    ERROR_PAGE_BUTTON:'زر الرجوع',
    DASHBOARD_ROAD_TIME_COLOR:'ٌوقت على الطريق',
    DASHBOARD_VISIT_TIME_COLOR:'وقت الزيارة',



    MANDOOBEEN_SETTINGS:'اعدادت-المندوبين',
    SETTINGS_UPDATE_BUTTON:'تحديث',
    
    

    SETTINGS_ADD_NEW_KEY_SECTION_AND_VALUE:'اضافة مفتاح قسم جديد',
    SETTINGS_edit_NEW_KEY_SECTION_AND_VALUE:'تعديل مفتاح القسم',
    SETTINGS_edit_value_of_SECTION_key:'تعديل محتوى مفتاح القسم',

    SETTINGS_delete_NEW_KEY_SECTION_AND_VALUE:'حذف مفتاح القسم',

    SETTINGS_ADD_NEW_KEY_SECTION_NAME:'مفتاح القسم',

    SETTINGS_Edit_KEY_AND_VALUE:'تعديل مفتاح',
    SETTINGS_DELETE_KEY_AND_VALUE:'حذف مفتاح',
    SETTINGS_Edit_VALUE_Key:'تعديل المحتوى',

    SETTINGS_ADD_NEW_KEY_AND_VALUE:'اضافة مفتاح  جديد',
    SETTINGS_ADD_NEW_KEY_NAME:'مفتاح',
    

    SETTINGS_ADD_NEW_VALUE_NAME_EN:'محتوى الانكليزي',
    SETTINGS_ADD_NEW_VALUE_NAME_AR:'المحتوى العربي',
    SETTINGS_ADD_NEW_VALUE_NAME:'المحتوى',


    SETTINGS_ADD_BUTTON:'اضافة',
    SETTINGS_edit_BUTTON:'تعديل',
    SETTINGS_delete_BUTTON:'حذف',

    GRID_LAYOUT:'واجهة الجداول',
    GRID_MODE:'عامودي او افقي',
    GRID_CEHCK_LABEL:'الوضع',

    
    SELECT_FILTER_BY:'نوع البحث',
    SEARCH_BOX:'اسم المفتاح',
    NO_DATA:'لا توجد بيانات'
    

    },
    COMPANY:{
      ID:"ID",
      IMAGE:"الصورة",
      COMPANY_NAME:"اسم الشركة",
      NUMBER_OF_USERS:"عدد المستخدمين",
      PRICE:"السعر",
      PERIOD_NAME:"فترة الاشتراك",
      DISCOUNT_PERCENTAGE:"نسبة الخصم",
      ACTIONS:"الوظائف",

      FORMADD_TITLE:'اضافة شركة' ,
      FORMEDIT_TITLE:'تعديل بيانات الشركة' ,
      FORM_COMPANY_NAME:'اسم الشركة',
      FORM_NUMBER_OF_USERS:'عدد المستخدمين',
      FORM_TRIAL_PERIOD:'فترة تجريبية',
      FORM_PRICE:'سعر',
      FORM_NUMBER_OF_ALLOW_DEMO_USER_PER_MONTH:'عدد المسموح به للمستخدمين التجريبيين شهريًا',
      FORM_BILLING_FREQUENCY:'تكرار الفواتير',
      FORM_SELECT_ABILLING_FREQUENCY:'حدد تكرار الفواتير',
      FORM_PARENT_COMPANY:'الشركة الام',
      FORM_SELECT_COMPANY:'اختر شركة',
      FORM_ALLOW_DEMO:'السماح بالعرض التجريبي',
      FORM_ACTIVE_STATUS:'حالة النشاط',
      FORM_IS_REAL_COMPANY:'هي شركة حقيقية',
      FORM_LOGO:'شعار',
      FORM_NO_LOGO_SELECTED:'لم يتم تحديد شعار',
      FORM_SUBMIT:'ارسال',
    },
    SETTINGS_SECTIONS:{
      GLOBAL:'عام',
      GLOBAL_SETTINGS:'اعدادات عامة',

      DASHBOARD:"لوحة التحكم",
      DASHBOARD_SETTINGS:'اعدادات لوحة التحكم',

      SIDEMENU:"القائمة الجانبية",
      SIDEMENU_SETTINGS:'اعدادات القائمة الجانبية',

      APPBAR:'شريط الادوات',
      APPBAR_SETTINGS:'اعدادات شريط الادوات',


      TOOLBAR:"شريط الادوات",
      TOOLBAR_SETTINGS:'اعدادات شريط الادوات',

      USERS:'المستخدمين',
      USERS_SETTINGS:'اعدادات المستخدمين',

      VISITS:'الزيارات',
      VISITS_SETTINGS:'اعدادات الزيارات',

      POINTS:'النقاط',
      POINTS_SETTINGS:'اعدادات النقاط',

      POINTS_REQUESTS:'طلب النقاط',
      POINTS_REQUESTS_SETTINGS:'اعدادات طلب النقاط',

      REGION:'المنطقة',
      REGION_SETTINGS:'اعدادات المنطقة',

      SURVEY:'الاستطلاع',
      SURVEY_SETTINGS:'اعدادات الاستطلاع',

      JOURNEYS:'المسارات',
      JOURNEYS_SETTINGS:'اعدادات المسارات',

      ROLES:'الصلاحيات',
      ROLES_SETTINGS:'اعدادات الصلاحيات',

      DEPARTMENTS:'الاقسام',
      DEPARTMENTS_SETTINGS:'اعدادات الاقسام',

      DISTIRCTS:'المقاطعات',
      DISTIRCTS_SETTINGS:'اعدادات المقاطعات',

      FIELD_LISTS:'لوائح الحقول',
      FIELD_LISTS_SETTINGS:'اعدادات لوائح الحقول',

      POINT_STATUS:'حالة النقاط',
      POINT_STATUS_SETTINGS:'اعدادات حالة النقاط',

      VISIT_REPORT:'نقرير الزيارات',
      VISIT_REPORT_SETTINGS:'اعدادات تقرير الزيارات',

      ATTENDANCE_REPORT:'تقرير الحضور',
      ATTENDANCE_REPORT_SETTINGS:'اعدادات تقرير الحضور',

      FOLLOWUP_REPORT:'تقرير المتابعات',
      FOLLOWUP_REPORT_SETTINGS:'اعدادات تقرير المتابعات',

      NOTIFICATIONS:'الاشعارات',
      NOTIFICATIONS_SETTINGS:'اعدادات الاشعارات',

      SHIFTS:'الوجبات',
      SHIFTS_SETTINGS:'اعدادات الوجبات',

      PROFILE:'الملف الشخصي',
      PROFILE_SETTINGS:'اعدادات الملف الشخصي',

      GROUPS:'المجموعات',
      GROUPS_SETTINGS:'اعدادت المجموعات',
      
      // LOGIN_PAGE:'تسجيل الدخول',
      // LOGIN_PAGE_SETTINGS:" اعدادات تسجيل الدخول",

      SETTINGS:'الاعدادات',
      SETTINGS_SETTINGS:'الاعدادات',

      TOOLTIP:'الاختصارات',
      TOOLTIP_SETTINGS:'اعدادات الاختصارات',
      
      SETTINGS_OTHERS:'اخرى',
      SETTINGS_OTHERS_SETTINGS:'اعدادات اضافية',

      COMPANY:'الشركات',
      COMPANY_SETTINGS:'اعدادات الشركات',

      FOLLOW_UP_STATUS:'حالات المتابعة',
      FOLLOW_UP_STATUS_SETTINGS:'اعدادت حالات المتابعة',

      ERROR_PAGE:'صفحات الخطأ',
      ERROR_PAGE_SETTINGS:'إعدادات صفحات الخطأ',
      
      SWEATALERT_MESSAGES:'تنبيهات ',
      SWEATALERT_MESSAGES_SETTINGS:'إعدادات التنبيهات',

      LANGUAGES:'اللغات',
      LANGUAGES_SETTINGS:'اعدادات اللغات',

    },
    SWEATALERT_MESSAGES:{
      WARNNING_MESSAGE:'تنبيه',
      ADD_NEW_KEY_AND_VALUES_message:'يجب أن تملأ جميع الحقول',
      ADD_NEW_KEY_AND_VALUES_EXISTS:'هذا المقتاح موجود بالفعل',
      IMAGE_NOT_FOUND:'يجب ان تختار صورة',

      WARRNING_BUTTON:'حسنا',
      USER_ID_NOT_FOUND:'معرف المستخدم غير موجود',
    },
    LOGIN_PAGE:{
       SINGIN_TITLE:'تسجيل دخول',
       SIGNIN_USERNAME:'اسم المستخدم',
       SIGNIN_PASSWORD:'كلمة المرور',
       SIGNIN_USERNAME_ERROR_SHORT:'اسم المستخدم قصير',
       SIGNIN_USERNAME_ERROR_BLANCK:"لا يمكن أن يكون حقل اسم المستخدم فارغًا",
       SIGNIN_PASSWORD_ERROR_SHORT:'يجب أن تكون كلمة المرور أكثر من 3 أحرف',
       SIGNIN_PASSWORD_ERROR_BLANCK:"لا يمكن أن يكون حقل كلمة المرور فارغًا",
       SIGNIN_BUTTON:'تسجيل الدخول',
       SIGNIN_OR_CONTINU_WITH:'أو استمر مع',
       WELLCOME_MESSAGE:'مرحبا بكم',
       SUBWELLCOME_MESSAGE:'في مندوبين',
       INFO_MESSAGE_1:'منصة تساعدك  على إدارة مندوبي الشركة من خلال توفير مميزات متطورة تمكنك من التحكم في فرق المندوبين ومعرفة كافة تحركاتهم وادائهم',
      //  INFO_MESSAGE_2:'dashboards full of beautiful and rich modules. Join us and start',
      //  INFO_MESSAGE_3:'building your system today.',
       FOOTER_MESSAGE:"انضم إلينا أكثر من 17 ألف شخص ، حان دورك"

    },
    ERROR_PAGE:{
      ERROR_TITLE_404_ERROR:'خطأ',
      ERROR_TITLE_404:'لم يتم العثور على الصفحة',
      ERROR_TITLE_500_ERROR:'خطأ',
      ERROR_TITLE_500:'خطأ في الخادم الداخلي',
      ERROR_TITLE_401_ERROR:'خطأ',
      ERROR_TITLE_401:'خطأ في الوصول غير المصرح به',
      Go_BACK_BUTTON:'الرجوع الى ',

    },
    GROUPS:{
      TITLE:'لائحة المجاميع',
      ID: "ID",
      NAME: 'الاسم',
      COLOR:'اللون',
      ACTIONS:'الوظائف',
      FORM_EDIT_TITLE:'تعديل المجموعة',
      FORM_ADD_TITLE:'اضافة مجموعة',
      FORM_NAME:'الاسم',
      FORM_COLOR:'اللون',
      FORM_SUBMIT:'ارسال',

    },
    PROFILE:{
      USER_TITLE:'تعديل الملف الشخصي',
      FULL_NAME:'الاسم كامل',
      USERNAME:'اسم المستخدم',
      PHONE:'رقم الهاتف',
      PASSWORD:'كلمة المرور',
      UPDATE_BUTTON:'تحديث البيانات'
    },
    TOOLBAR:{
      SEARCH_PUPOP_TITLE:'ابحث عن شركة'
    },
    FOLLOW_UP_STATUS:{
      ID:'ID',
      NAME:'الاسم',
      ACTIONS:'الوظائف',
      FORMADD_NAME:"الاسم"
    },
    SHIFTS:{
      ID:'ID',
      SHIFTS_STARTS_TIME:'وقت البدء',
      SHIFTS_END_TIME:'وقت الانهاء',
      SHIFTS_REQUIRED_TIME:'الوقت المطلوب',
      SHIFTS_DAY_DIFFERENCE:'فرق الايام',
      ACTIONS:'الوظائف',
      HEADER_TITLE:'قائمت الوجبات',

      FORM_START_WORKING_HOURS:'وقت بدء العمل',
      FORM_END_WORKING_HOURS:'وقت انهاء العمل',
      FORM_REQUIRED_WORKING_HOURS:'ساعات العمل المطلوبة',
    },
    TOOLTIP:{
      CREATE:'انشاء',
      EXPORT:'تصدير',
      MAP:'خريطة',
      SEND_TELEGRAM:'ارسال تليغراف',
      IMPORT:'تحميل',
      VIEW:'عرض',
      COPY:'نسخ',
      PAST:'لصق'
    },
    LANGUAGES:{
      title:'ٌائمة اللغات',
      gird_name:'الاسم',
      gird_code:'الرمز',
      grid_rtl:'اتجاه النص',
      grid_image:'الصورة',
      grid_id:'ID',
      grid_actions:'الوظائف',
      form_name:'الاسم',
      form_code:'الرمز',
      form_rtl:'اتجاه النص',
      form_image:'الصورة',


    }

  },

};
export const TranslateSlice = createSlice({
  name: "translateData",
  initialState: initialState,
  reducers: {
    setTranslateEnglish:(state,action)=>{
      state.translation_en=action.payload
    },
    setTranslateArabic:(state,action)=>{
      state.translation_ar=action.payload
    },
  },
});

export const {
  setTranslateEnglish,
  setTranslateArabic,
} = TranslateSlice.actions;

// export const sideMenuMode = state => state.settingsData.sideMenuMode;

export default TranslateSlice.reducer;
