import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea, FilePicker } from 'evergreen-ui';
import DialogForm from "../Share/DialogForm";
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button, Table } from "react-bootstrap";
import { Avatar } from "evergreen-ui"
import helpers from "../../../assets/js/helper";
import { Divider } from "@material-ui/core";
import { AiFillCloseCircle } from 'react-icons/ai'
import { Box, IconButton, Typography } from '@mui/material';
import APP_CONSTANTS from "../../../assets/js/constants";
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import ReactImageZoom from 'react-image-zoom';
import ImgsViewer from "react-images-viewer";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const PointPreview = (props) => {
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const selectedFields= [
        {
            id: 1,
            name: "name",
            title:t('POINTS.NAME'),
            selected: false,
            has_sub_name: false,
            value_name: "name",
            has_changes: false
        },
        {
            id: 2,
            name: "owner_name",
            title:t('POINTS.OWNER_NAME'),
            selected: false,
            has_sub_name: false,
            value_name: "owner_name",
            has_changes: false
        },
        {
            id: 3,
            name: "owner_type",
            title:t('POINTS.OWNER_TYPE'),
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "owner_type_id"
        },
        {
            id: 4,
            name: "phone",
            title:t('POINTS.PHONE'),
            has_sub_name: false,
            selected: false,
            value_name: "phone"
        },
        {
            id: 5,
            name: "near_by",
            title:t('POINTS.FORMVIEW_NEAR_BY'),
            has_sub_name: false,
            selected: false,
            value_name: "near_by"
        },
        {
            id: 6,
            name: "location_category",
            title:t('POINTS.FORMVIEW_LOCATION_CATEGORY'),
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "location_category_id"
        },
        {
            id: 7,
            name: "classification",
            title:t('POINTS.FORMVIEW_CLASSIFICATION'),
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "classification_id"
        },
        {
            id: 8,
            name: "category",
            title:t('POINTS.FORMVIEW_CATEGORY'),
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "category_id"
        },
        {
            id: 9,
            name: "point_type",
            title:t('POINTS.FORMVIEW_POINT_TYPE'),
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "point_type_id"
        },
        {
            id: 10,
            name: "region",
            title:t('POINTS.REGION'),
            sub_name: "name",
            has_sub_name: true,
            selected: false,
            value_name: "region_id"
        },
        {
            id: 11,
            name: "latitude",
            title:t('POINTS.FORMVIEW_LATITUDE'),
            has_sub_name: false,
            selected: false,
            value_name: "latitude"
        },
        {
            id: 11,
            name: "longitude",
            title:t('POINTS.FORMVIEW_LONGITUDE'),
            has_sub_name: false,
            selected: false,
            value_name: "longitude"
        },
        {
            id: 12,
            name: "has_delivery",
            title:t('POINTS.FORMVIEW_HAS_DELIVERY'),
            has_sub_name: false,
            selected: false,
            value_name: "has_delivery"
        },
        {
            id: 13,
            name: "image",
            title:t('POINTS.FORMVIEW_IMAGE'),
            has_sub_name: false,
            selected: false,
            value_name: "image"
        }
    ]
    const [imageOpen,setImageOpen]=useState(false)
    const [currentImage,setCurrentImage]=useState('')

    const [loading, setloading] = useState(true);
    const directions= useSelector(state=> state.settingsData.directions)
    const MainTheme= useSelector(state=> state.settingsData.MainTheme)

    useEffect(() => {
        setloading(false)
        return () => {

        };
    }, [loading, props.showPointPreview]);




    return (
        
        <DialogForm
          open={props.showPointPreview}
          close={props.setshowPointPreview}
          loaderActive={loading}
          headerChildren={(
              <>
                 <Box sx={{backgroundColor:MainTheme?.paperColor,width:'100%',height:'55px'}}>
                    <IconButton aria-label={"Close"} onClick={() => { props.setshowPointPreview(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px' }}>
                        <AiFillCloseCircle style={{ color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor, }} />
                    </IconButton>
                </Box>
              </>
            )}
          hasHeader={true}
          CustomeHeight="fit-content"
        >
        
        <Box
          sx={{
            position:'fixed',
            top:'0',
            left:'0',
            // transform:'translate(-50%,-50%)',
            zIndex:4000,
            boxShadow:'0 0 20px 6px #00000055',
            display: imageOpen?'flex':'none',
            backdropFilter:'blur(5px)',
            width:'100%',
            height:'100%',
            justifyContent: 'center',
            alignItems: 'center',
            transition:'all 1s ease-in-out '

          }}
          onClick={()=>{
            // setImageOpen(false)
          }}
        >
          <img 
            src={currentImage} 
            style={{
                width:'600px',
                height:'600px',
                transform:imageOpen?'scale(1)':'scale(0)',
                transition:'all 1s ease-in-out '

            }}
          />
          <AiFillCloseCircle
            style={{
                position:'absolute',
                top:'5%',
                right:'15%',
                fontSize:'35px',
                cursor:'pointer'
            }}
            onClick={()=>{
               setImageOpen(false)
            }}
          />
        </Box>
     
      
        <Table striped bordered  style={{marginTop:30,backgroundColor:MainTheme?.paperColor}}>
            <thead style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor,backgroundColor:MainTheme?.paperTextColor+'77'}}>
                <tr >
                    <th style={{textAlign:directions=='rtl'?'right':'left'}}>{t('POINTS.FORMvIEW_FIELD_NAME')}</th>
                    <th style={{textAlign:directions=='rtl'?'right':'left'}}>{t('POINTS.FORMVIEW_POINT_NAME')}</th>
                </tr>
            </thead>
            <tbody  style={{color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor,backgroundColor:MainTheme?.paperColor}}>

                {selectedFields.map((field) => {
                    if (field.value_name != "image" && field.value_name != "images" && field.value_name != "phones") {
                        try {
                            var pointRequestTitle = field.has_sub_name && props.pointRequest[field.name] ? props.pointRequest[field.name][field.sub_name] : props.pointRequest[field.name]

                        }
                        catch (error) {
                            console.log(field);
                            console.log(error);
                        }
                        return (
                            <tr>
                                <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>{field.title} </td>
                                <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>{pointRequestTitle}</td>
                            </tr>)
                    }
                    else {
                        return null
                    }
                })}

                <tr>
                    <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>{t('POINTS.FORMVIEW_IMAGE')}</td>
                    <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor,position:'relative'}}>
                        {
                        !props.pointRequest?.profile_image[0]?.image_url?
                        <Avatar
                            src={helpers.getImageUrl(props.pointRequest?.profile_image[0]?.image_url)}
                            name={props.pointRequest?.name}
                            size={40}
                            sx={{
                                backgroundColor:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor+'99',
                                color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor,
                            }}
                        />:
                            <Box
                            sx={{
                                positiom:'relative',zIndex:3000,top:0,left:0,
                               '& .js-image-zoom__zoomed-image':{
                                position:'relative',
                                top:'50%',
                                right:'10% !important',
                                // height:'400px !important',
                                width:'400px !important',
                                overflow: 'hidden',
                                // left:'0%',
                           
                               },
                               width:'40%',
                            }}
                            className="img-zoom-container img-container"
                            >
                                {/* <ReactImageZoom
                                  width={200} 
                                  height={200}
                                  zoomWidth={500}
                                  img={helpers.getImageUrl(props.pointRequest?.profile_image[0]?.image_url)}
                                  zoomPosition="right"
                                
                                /> */}
                                <img  onClick={()=>{
                                        setCurrentImage(helpers.getImageUrl(props.pointRequest?.profile_image[0]?.image_url))
                                        setImageOpen(true)
                                        
                                }}  src={helpers.getImageUrl(props.pointRequest?.profile_image[0]?.image_url)} style={{
                                    height:'80px',width:'200px',objectFit:'scale-down',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }} />

                              

                            </Box>

                        
                     }
                    </td>

                </tr>
                {props.pointRequest?.other_images && props.pointRequest?.other_images.length>0?
                <tr>
                    <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>Other images</td>
                    <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>
                    {props.pointRequest?.other_images.map((image)=>{
                        return
                        !image?.image_url?
                         <Avatar
                        src={helpers.getImageUrl(image?.image_url)}
                        name={props.pointRequest?.name}
                        size={40}
                        />:
                        <img
                            onClick={()=>{
                                setCurrentImage(helpers.getImageUrl(props.pointRequest?.profile_image[0]?.image_url))
                                setImageOpen(true)
                                
                            }}
                            src={helpers.getImageUrl(image?.image_url)} style={{
                                height:'80px',width:'200px',objectFit:'scale-down',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }} 
                        />
                       })}
                        
                    </td>

                </tr>
                :null}
                {props.pointRequest?.phones && props.pointRequest?.phones.length>0?
                <tr>
                    <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>Phones</td>
                    <td style={{textAlign:directions=='rtl'?'right':'left',backgroundColor:MainTheme?.paperColor}}>
                    {props.pointRequest?.phones.map((phone)=>{
                        return <p>{"Name:"+phone.name+"- Phone:"+phone.phone+"- Job Title:"+phone.job_title}</p>
                    })}
                        
                    </td>

                </tr>
                :null}


            </tbody>
        </Table>



        <br />
        <br />
        <br />
        <br />

        </DialogForm>
        

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '90%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default PointPreview;
