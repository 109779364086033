import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea,FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux';

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const VisitForm = (props) => {
    const [loading, setloading] = useState(true);
    const directions = useSelector(state => state.settingsData.directions)
    const MainTheme = useSelector(state => state.settingsData.MainTheme)
    const token = cookies.get("token")
    const [ownerTypes, setownerTypes] = useState([]);
    const [locationCategories, setlocationCategories] = useState([]);
    const [categories, setcategories] = useState([]);
    const [visitTypes, setvisitTypes] = useState([]);
    const [regions, setregions] = useState([]);
    const [classifications, setclassifications] = useState([]);
    
    const [object, setobject] = useState( {
        id: null,
        name: null,
        owner_name:null,
        owner_type_id:null,
        owner_type:{
            id:null,
            name:null,
        },
        phone:null,
        image:null,
        near_by:null,
        location_category_id:null,
        location_category:{
            id:null,
            name:null
        },
        classification_id:null,
        classifications:{
            id:null,
            name:null
        },
        category_id:null,
        category:{
            id:null,
            name:null
        },
        has_delivery:false,
        external_customer_id:null,
        visit_type_id:null,
        visit_type:{
            id:null,
            name:null
        },
        longitude:null,
        latitude:null,
        region_id:null,
        region:{
            id:null,
            name:null,
        },

    });
    const getResources = async () => {
        try {
            setloading(true)
            var resourcesResult = await axios({
                url: Host + "owner_types_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    setownerTypes(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error owner")
            }
            resourcesResult = await axios({
                url: Host + "location_categories_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setlocationCategories(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error location category")
            }
            resourcesResult = await axios({
                url: Host + "classifications_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setclassifications(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            resourcesResult = await axios({
                url: Host + "categories_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setcategories(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            resourcesResult = await axios({
                url: Host + "visit_types_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setvisitTypes(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            resourcesResult = await axios({
                url: Host + "regions_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setregions(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }

            if(props&& props.visit&& props.visit.id>0)
            {
                setobject({...object,...props.visit});
            }
            else{
                
                setobject(
                    {
                        id: null,
                        name: null,
                        owner_name:null,
                        owner_type_id:null,
                        owner_type:{
                            id:null,
                            name:null,
                        },
                        phone:null,
                        image:null,
                        near_by:null,
                        location_category_id:null,
                        location_category:{
                            id:null,
                            name:null
                        },
                        classification_id:null,
                        classifications:{
                            id:null,
                            name:null
                        },
                        category_id:null,
                        category:{
                            id:null,
                            name:null
                        },
                        has_delivery:false,
                        external_customer_id:null,
                        visit_type_id:null,
                        visit_type:{
                            id:null,
                            name:null
                        },
                        longitude:null,
                        latitude:null,
                        region_id:null,
                        region:{
                            id:null,
                            name:null,
                        },
                        
                    }
                )
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {

            let formdata = new FormData();

            console.log(object);
            if(object.name)
            formdata.append("name", object.name)
            
            if (object.owner_name)
            formdata.append("owner_name", object.owner_name)
            if(object.owner_type_id)formdata.append("owner_type_id", object.owner_type_id)
            if(object.latitude)formdata.append("latitude", object.latitude)
            if(object.longitude)formdata.append("longitude", object.longitude)
            if(object.near_by)formdata.append("near_by", object.near_by)
            if(object.has_delivery)formdata.append("has_delivery", object.has_delivery)
            if(object.region_id)formdata.append("region_id", object.region_id)
            if(object.visit_type_id)formdata.append("visit_type_id", object.visit_type_id)
            if(object.location_category_id)formdata.append("location_category_id", object.location_category_id)
            if(object.classification_id)formdata.append("classification_id", object.classification_id)
            if(object.category_id)formdata.append("category_id", object.category_id)
            if(object.external_customer_id)formdata.append("external_customer_id", object.external_customer_id)
            if(object.phone)formdata.append("phone", object.phone)
            if(object.image)formdata.append("image", object.image)
            
            
        
            if (object.id) {

                formdata.append("_method", "PUT");
                const resourcesResult = await axios({
                    url: Host + "visits/"+object.id,
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowVisitForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowVisitForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowVisitForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "visits",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowVisitForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowVisitForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowVisitForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowVisitForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const handleInputChange = (event,has_delivery) => {
        const target = event.target;
        
        if(target.checked)
        {
            setobject({ ...object, has_delivery: 1 });
        }
        else
        {
            setobject({ ...object, has_delivery: 0 });
        }
        

    };

    const onImageChange = (fileList)=>{
        console.log(fileList);
        if(fileList && fileList.length>0)
        {
            setobject({
                ...object,
                image:fileList[0]
            })
        }

    }

    useEffect(() => {
        if(loading)
        getResources()
        

        
        return () => {

        };
    }, [loading,props.showVisitForm]);


    return (
        loading ? <></> :
        <Dialog
                isShown={props.showVisitForm}
                onCloseComplete={() => props.setshowVisitForm(false)}
                hasHeader={false}
                shouldCloseOnOverlayClick={false}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={() => {submitForm()}}
                loaderActive={loading}
                
                >
            <form >
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input className="form-control" 
                        id="name"
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                     />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Owner name</label>
                    <input className="form-control" 
                        id="owner_name"
                        value={object.owner_name}
                        onChange={event => { setobject({ ...object, owner_name: event.target.value }); }}
                     />
                </div>
                <div className="form-group">
                    <label htmlFor="owner_type_id">owner type</label>
                    <Select onChange={e => { setobject({ ...object, owner_type:{
                        name:e.label,
                        id:e.value
                    }, owner_type_id: e.value }); }}
                        defaultValue={{value:object.owner_type?.id,label:object.owner_type?.name}}
                        value={{value:object.owner_type?.id,label:object.owner_type?.name}}
                        styles={customStyles}
                        options={ownerTypes}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input className="form-control" 
                        id="phone"
                        value={object.phone}
                        onChange={event => { setobject({ ...object, phone: event.target.value }); }}
                     />
                </div>
                <div className="form-group">
                    <label htmlFor="image">Image </label>
                    <FilePicker onChange={(e)=>onImageChange(e)} name="image" width={350} height={24} />
                </div>
                <div className="form-group">
                    <label htmlFor="latitude">Latitude</label>
                    <input className="form-control" 
                        id="latitude"
                        value={object.latitude}
                        onChange={event => { setobject({ ...object, latitude: event.target.value }); }}
                     />
                </div>
                <div className="form-group">
                    <label htmlFor="longitude">Longitude</label>
                    <input className="form-control" 
                        id="longitude"
                        value={object.longitude}
                        onChange={event => { setobject({ ...object, longitude: event.target.value }); }}
                     />
                </div>
                <div className="form-group">
                    <label htmlFor="region_id">Region</label>
                    <Select onChange={e => { setobject({ ...object, region:{
                        name:e.label,
                        id:e.value
                    }, region_id: e.value }); }}
                        defaultValue={{value:object.region?.id,label:object.region?.name}}
                        value={{value:object.region?.id,label:object.region?.name}}
                        styles={customStyles}
                        options={regions}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Near by</label>
                    
                    <textarea className="form-control" 
                        id="near_by"
                        value={object.near_by}
                        onChange={event => { setobject({ ...object, near_by: event.target.value }); }}
                     ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="location_category_id">Location Category</label>
                    <Select onChange={e => { setobject({ ...object, location_category:{
                        name:e.label,
                        id:e.value
                    }, location_category_id: e.value }); }}
                        defaultValue={{value:object.location_category?.id,label:object.location_category?.name}}
                        value={{value:object.location_category?.id,label:object.location_category?.name}}
                        styles={customStyles}
                        options={locationCategories}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="classification_id">Classification</label>
                    <Select onChange={e => {setobject({ ...object, classification:{
                        name:e.label,
                        id:e.value
                    }, classification_id: e.value }); }}
                        defaultValue={{value:object.classification?.id,label:object.classification?.name}}
                        value={{value:object.classification?.id,label:object.classification?.name}}
                        styles={customStyles}
                        options={classifications}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="cateogry_id">Category</label>
                    <Select onChange={e => { setobject({ ...object, category:{
                        name:e.label,
                        id:e.value
                    }, category_id: e.value }); }}
                        defaultValue={{value:object.category?.id,label:object.category?.name}}
                        value={{value:object.category?.id,label:object.category?.name}}
                        styles={customStyles}
                        options={categories}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="visit_type_id">Status</label>
                    <Select onChange={e => { setobject({ ...object, visit_type:{
                        name:e.label,
                        id:e.value
                    }, visit_type_id: e.value }); }}
                        defaultValue={{value:object.visit_type?.id,label:object.visit_type?.name}}
                        value={{value:object.visit_type?.id,label:object.visit_type?.name}}
                        styles={customStyles}
                        options={visitTypes}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="external_customer_id">Jahizni Customer ID</label>
                    <input className="form-control" 
                        id="external_customer_id"
                        value={object.external_customer_id}
                        onChange={event => { setobject({ ...object, external_customer_id: event.target.value }); }}
                     />
                </div>
                <div className="form-check">
                    <input className="form-check-input" 
                    type="checkbox"
                        value={object.has_delivery} 
                        checked={object.has_delivery?true:false}
                        onChange={(e)=>handleInputChange(e,object.has_delivery)}
                        id={"has_delivery"}/>
                    <label className="form-check-label" htmlFor={"has_delivery"}>
                    Has delivery
                    </label>
                </div>
               
                
                
            </form>
        </Dialog>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default VisitForm;
