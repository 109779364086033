import * as React from 'react';
import {
  styled, darken, alpha, lighten,
} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  Resources,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import WbSunny from '@mui/icons-material/WbSunny';
import FilterDrama from '@mui/icons-material/FilterDrama';
import Opacity from '@mui/icons-material/Opacity';
import {useTranslation} from "react-i18next";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ColorLens from '@mui/icons-material/ColorLens';
// import { owners } from '../../../demo-data/tasks';

const owners = [
    {
      title: 'Recall Rebate Form',
      priorityId: 2,
      startDate: '2018-04-17T09:30',
      endDate: '2018-04-17T10:00',
    },
    {
      title: 'Create Report on Customer Feedback',
      priorityId: 2,
      startDate: '2018-04-17T10:30',
      endDate: '2018-04-17T12:00',
    },
    {
      title: 'Review Customer Feedback Report',
      priorityId: 2,
      startDate: '2018-04-17T12:15',
      endDate: '2018-04-17T13:30',
    },
    {
      title: 'Google AdWords Strategy',
      priorityId: 2,
      startDate: '2018-04-20T09:30',
      endDate: '2018-04-20T11:30',
    },
    {
      title: 'Rollout of New Website and Marketing Brochures',
      priorityId: 2,
      startDate: '2018-04-19T10:30',
      endDate: '2018-04-19T12:30',
    },
    {
      title: 'Create New Shipping Return Labels',
      priorityId: 3,
      startDate: '2018-04-19T13:00',
      endDate: '2018-04-19T14:30',
    },
    {
      title: 'Update Sales Strategy Documents',
      priorityId: 1,
      startDate: '2018-04-18T11:00',
      endDate: '2018-04-18T11:30',
    },
    {
      title: 'Website Re-Design Plan',
      priorityId: 2,
      startDate: '2018-04-27T09:30',
      endDate: '2018-04-27T11:30',
      allDay: true,
    },
    {
      title: 'Book Flights to San Fran for Sales Trip',
      priorityId: 2,
      startDate: '2018-04-27T12:00',
      endDate: '2018-04-27T13:00',
    },
    {
      title: 'Install New Router in Dev Room',
      priorityId: 2,
      startDate: '2018-04-27T14:30',
      endDate: '2018-04-27T15:30',
    },
    {
      title: 'Approve Personal Computer Upgrade Plan',
      priorityId: 2,
      startDate: '2018-04-23T10:00',
      endDate: '2018-04-23T11:00',
    },
    {
      title: 'Final Budget Review',
      priorityId: 2,
      startDate: '2018-04-23T12:00',
      endDate: '2018-04-23T13:35',
    },
    {
      title: 'New Brochures',
      priorityId: 3,
      startDate: '2018-04-23T14:30',
      endDate: '2018-04-23T15:45',
    },
    {
      title: 'Install New Database',
      priorityId: 3,
      startDate: '2018-04-24T09:45',
      endDate: '2018-04-24T11:15',
    },
    {
      title: 'Approve New Online Marketing Strategy',
      priorityId: 2,
      startDate: '2018-04-24T12:00',
      endDate: '2018-04-24T14:00',
    },
    {
      title: 'Upgrade Personal Computers',
      priorityId: 2,
      startDate: '2018-04-24T15:15',
      endDate: '2018-04-24T16:30',
    },
    {
      title: 'Customer Workshop',
      priorityId: 3,
      startDate: '2018-04-25T11:00',
      endDate: '2018-04-25T12:00',
      allDay: true,
    },
    {
      title: 'Prepare 2019 Marketing Plan',
      priorityId: 1,
      startDate: '2018-04-25T11:00',
      endDate: '2018-04-25T13:30',
    },
    {
      title: 'Brochure Design Review',
      priorityId: 1,
      startDate: '2018-04-25T14:00',
      endDate: '2018-04-25T15:30',
    },
    {
      title: 'Create Icons for Website',
      priorityId: 3,
      startDate: '2018-04-26T10:00',
      endDate: '2018-04-26T11:30',
    },
    {
      title: 'Upgrade Server Hardware',
      priorityId: 2,
      startDate: '2018-04-26T14:30',
      endDate: '2018-04-26T16:00',
    },
    {
      title: 'Submit New Website Design',
      priorityId: 1,
      startDate: '2018-04-26T16:30',
      endDate: '2018-04-26T18:00',
    },
    {
      title: 'Launch New Website',
      priorityId: 3,
      startDate: '2018-04-26T12:20',
      endDate: '2018-04-26T14:00',
    },
    {
      title: 'Non-Compete Agreements',
      priorityId: 1,
      startDate: '2018-04-30T12:20',
      endDate: '2018-04-30T13:00',
    },
    {
      title: 'Approve Hiring of John Jeffers',
      priorityId: 3,
      startDate: '2018-04-30T13:30',
      endDate: '2018-04-30T14:00',
    },
    {
      title: 'Review Training Course for any Ommissions',
      priorityId: 3,
      startDate: '2018-04-30T09:30',
      endDate: '2018-04-30T10:00',
    },
    {
      title: 'Update NDA Agreement',
      priorityId: 2,
      startDate: '2018-05-01T12:00',
      endDate: '2018-05-01T15:00',
    },
    {
      title: 'Update Employee Files with New NDA',
      priorityId: 2,
      startDate: '2018-05-02T09:00',
      endDate: '2018-05-02T10:00',
    },
    {
      title: 'Submit Questions Regarding New NDA',
      priorityId: 2,
      startDate: '2018-05-02T10:15',
      endDate: '2018-05-02T10:45',
    },
    {
      title: 'Submit Signed NDA',
      priorityId: 2,
      startDate: '2018-05-02T10:45',
      endDate: '2018-05-02T11:15',
    },
    {
      title: 'Review Revenue Projections',
      priorityId: 1,
      startDate: '2018-05-02T12:00',
      endDate: '2018-05-02T12:30',
    },
    {
      title: 'Comment on Revenue Projections',
      priorityId: 1,
      startDate: '2018-05-02T13:00',
      endDate: '2018-05-02T14:00',
    },
    {
      title: 'Provide New Health Insurance Docs',
      priorityId: 2,
      startDate: '2018-05-03T10:00',
      endDate: '2018-05-03T11:30',
    },
    {
      title: 'Review Changes to Health Insurance Coverage',
      priorityId: 2,
      startDate: '2018-05-03T11:45',
      endDate: '2018-05-03T13:30',
    },
  ];

 
  
const PREFIX = 'Demo';

const classes = {
  cell: `${PREFIX}-cell`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  sun: `${PREFIX}-sun`,
  cloud: `${PREFIX}-cloud`,
  rain: `${PREFIX}-rain`,
  sunBack: `${PREFIX}-sunBack`,
  cloudBack: `${PREFIX}-cloudBack`,
  rainBack: `${PREFIX}-rainBack`,
  opacity: `${PREFIX}-opacity`,
  appointment: `${PREFIX}-appointment`,
  apptContent: `${PREFIX}-apptContent`,
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  flexContainer: `${PREFIX}-flexContainer`,
  tooltipContent: `${PREFIX}-tooltipContent`,
  tooltipText: `${PREFIX}-tooltipText`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  circle: `${PREFIX}-circle`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`,
};

const getBorder = theme => (`1px solid ${
  theme.palette.mode === 'light'
    ? lighten(alpha(theme.palette.divider, 1), 0.88)
    : darken(alpha(theme.palette.divider, 1), 0.68)
}`);

const DayScaleCell = props => (
  <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
);

// #FOLD_BLOCK
const StyledOpacity = styled(Opacity)(() => ({
  [`&.${classes.rain}`]: {
    color: '#4FC3F7',
  },
}));
// #FOLD_BLOCK
const StyledWbSunny = styled(WbSunny)(() => ({
  [`&.${classes.sun}`]: {
    color: '#FFEE58',
  },
}));
// #FOLD_BLOCK
const StyledFilterDrama = styled(FilterDrama)(() => ({
  [`&.${classes.cloud}`]: {
    color: '#90A4AE',
  },
}));

// #FOLD_BLOCK
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    color: '#78909C!important',
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 100,
    borderLeft: getBorder(theme),
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  [`&.${classes.sunBack}`]: {
    backgroundColor: '#FFFDE7',
  },
  [`&.${classes.cloudBack}`]: {
    backgroundColor: '#ECEFF1',
  },
  [`&.${classes.rainBack}`]: {
    backgroundColor: '#E1F5FE',
  },
  [`&.${classes.opacity}`]: {
    opacity: '0.5',
  },
}));
// #FOLD_BLOCK
const StyledDivText = styled('div')(() => ({
  [`&.${classes.text}`]: {
    padding: '0.5em',
    textAlign: 'center',
  },
}));
// #FOLD_BLOCK
const StyledDivContent = styled('div')(() => ({
  [`&.${classes.content}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
}));

// #FOLD_BLOCK
const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

// #FOLD_BLOCK
const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.${classes.flexibleSpace}`]: {
    flex: 'none',
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));
// #FOLD_BLOCK
const StyledAppointmentsAppointmentContent = styled(Appointments.AppointmentContent)(() => ({
  [`&.${classes.apptContent}`]: {
    '&>div>div': {
      whiteSpace: 'normal !important',
      lineHeight: 1.2,
    },
  },
}));

const appointments = [
  {
    id: 0,
    title: 'Watercolor Landscape',
    startDate: new Date(2018, 6, 23, 9, 30),
    endDate: new Date(2018, 6, 23, 11, 30),
    ownerId: 1,
  }, {
    id: 1,
    title: 'Monthly Planning',
    startDate: new Date(2018, 5, 28, 9, 30),
    endDate: new Date(2018, 5, 28, 11, 30),
    ownerId: 1,
  }, {
    id: 2,
    title: 'Recruiting students',
    startDate: new Date(2018, 6, 9, 12, 0),
    endDate: new Date(2018, 6, 9, 13, 0),
    ownerId: 2,
  }, {
    id: 3,
    title: 'Oil Painting',
    startDate: new Date(2018, 6, 18, 14, 30),
    endDate: new Date(2018, 6, 18, 15, 30),
    ownerId: 2,
  }, {
    id: 4,
    title: 'Open Day',
    startDate: new Date(2018, 6, 20, 12, 0),
    endDate: new Date(2018, 6, 20, 13, 35),
    ownerId: 6,
  }, {
    id: 5,
    title: 'Watercolor Landscape',
    startDate: new Date(2018, 6, 6, 13, 0),
    endDate: new Date(2018, 6, 6, 14, 0),
    rRule: 'FREQ=WEEKLY;BYDAY=FR;UNTIL=20180816',
    exDate: '20180713T100000Z,20180727T100000Z',
    ownerId: 2,
  }, {
    id: 6,
    title: 'Meeting of Instructors',
    startDate: new Date(2018, 5, 28, 12, 0),
    endDate: new Date(2018, 5, 28, 12, 30),
    rRule: 'FREQ=WEEKLY;BYDAY=TH;UNTIL=20180727',
    exDate: '20180705T090000Z,20180719T090000Z',
    ownerId: 5,
  }, {
    id: 7,
    title: 'Oil Painting for Beginners',
    startDate: new Date(2018, 6, 3, 11, 0),
    endDate: new Date(2018, 6, 3, 12, 0),
    rRule: 'FREQ=WEEKLY;BYDAY=TU;UNTIL=20180801',
    exDate: '20180710T080000Z,20180724T080000Z',
    ownerId: 3,
  }, {
    id: 8,
    title: 'Watercolor Workshop',
    startDate: new Date(2018, 6, 9, 11, 0),
    endDate: new Date(2018, 6, 9, 12, 0),
    ownerId: 3,
  },
];

const resources = [{
  fieldName: 'ownerId',
  title: 'Owners',
  instances: owners,
}];

const WeatherIcon = ({ id }) => {
  switch (id) {
    case 'sdsfsdfs':
      return <StyledOpacity className={classes.rain} fontSize="large" />;
    case 'sdfsdfs':
      return <StyledWbSunny className={classes.sun} fontSize="large" />;
    case 'sfsdfsd':
      return <StyledFilterDrama className={classes.cloud} fontSize="large" />;
    default:
      return <PermIdentityIcon />;
  }
};

// #FOLD_BLOCK
const CellBase = React.memo(({
  startDate,
  formatDate,
  otherMonth,
  // #FOLD_BLOCK
}) => {
  const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
  const isFirstMonthDay = startDate.getDate() === 1;
  const formatOptions = isFirstMonthDay
    ? { day: 'numeric', month: 'long' }
    : { day: 'numeric' };
  return (
    <StyledTableCell
      tabIndex={0}
      className={classNames({
        [classes.cell]: true,
        [classes.rainBack]: iconId === 0,
        [classes.sunBack]: iconId === 1,
        [classes.cloudBack]: iconId === 2,
        [classes.opacity]: otherMonth,
      })}
    >
      <StyledDivContent className={classes.content}>
        <WeatherIcon classes={classes} id={iconId} />
      </StyledDivContent>
      <StyledDivText className={classes.text}>
        {formatDate(startDate, formatOptions)}
      </StyledDivText>
    </StyledTableCell>
  );
});

const TimeTableCell = (CellBase);

const Appointment = (({ ...restProps }) => (
  <StyledAppointmentsAppointment
    {...restProps}
    className={classes.appointment}
  />
));

const AppointmentContent = (({ ...restProps }) => (
  <StyledAppointmentsAppointmentContent {...restProps} className={classes.apptContent}  onClick={(date)=>{
    console.log('appoint',date)
   }}/>
));

const FlexibleSpace = (({ ...restProps }) => {
    const [t, i18n] = useTranslation('common');

 return( <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>
    <div className={classes.flexContainer} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      {/* <ColorLens fontSize="large" htmlColor="#FF7043" /> */}
      <Typography variant="h5" style={{ marginLeft: '10px' }}>{t('JOURNEYS.SHOW_MANDOOB_PLAN')}</Typography>
    </div>
  </StyledToolbarFlexibleSpace>
)
});

export default function NewSchuler(props) {
    const [data,setData]=React.useState([])
    const  [currentDate,setCurrentDate] = React.useState(new Date())
    const [t, i18n] = useTranslation('common');

      // #FOLD_BLOCK
    const   commitChanges=({ added, changed, deleted })=>{
        this.setState((state) => {
          let { data } = state;
          if (added) {
            const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
            data = [...data, { id: startingAddedId, ...added }];
          }
          if (changed) {
            data = data.map(appointment => (
              changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
          }
          if (deleted !== undefined) {
            data = data.filter(appointment => appointment.id !== deleted);
          }
          return { data };
        });
    }

    const formatUserDataAfterGeted=(data)=>{

          let arr=[];
          setCurrentDate(new Date(data[0]?.startDate))
          data&&data?.length>0&&data?.map((itm)=>{
            arr.push({
                startDate: new Date(itm?.start_date),
                endDate: new Date(itm?.end_date),
                id: itm?.id,
                title:itm?.user?.username,
                ownerId:itm?.user?.id ,
            })
          })
          setData(arr)
  
    }

    React.useEffect(()=>{
        if(props?.listOfJourneyFOrUser&&props?.listOfJourneyFOrUser?.length>0){
            formatUserDataAfterGeted(props?.listOfJourneyFOrUser)
        }
    },[props?.listOfJourneyFOrUser])

    return (
         <Paper>
        <Scheduler
          data={data}
          
         
        >
          <EditingState
            onCommitChanges={commitChanges}
          />
          <ViewState
            defaultCurrentDate={currentDate}
          />

          <MonthView
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCell}
          />
          <Appointments
            appointmentComponent={Appointment}
            appointmentContentComponent={AppointmentContent}
         
          />
          <Resources
            data={resources}
          />
          <Toolbar
            flexibleSpaceComponent={FlexibleSpace}
          />
          <DateNavigator />
          {/* <EditRecurrenceMenu /> */}
          {/* <AppointmentTooltip
            showCloseButton
            showDeleteButton
            showOpenButton
          /> */}
          {/* <AppointmentForm /> */}
          {/* <DragDropProvider /> */}
        </Scheduler>
      </Paper>
    )
}
