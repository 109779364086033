import React, { useMemo,useEffect,useState,useCallback,Suspense } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate,useNavigate  } from 'react-router-dom';
import Context from '../../assets/js/context';
import Cookies from 'universal-cookie';
import SideNavBar, { pagesLinks } from './Share/SideNavBar';
import Box from '@mui/material/Box';
import ErrorPage from './RedirectsPages/ErrorPage';
import {useTranslation} from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
// import i18n from "i18next";
import LoadingPage from './Share/LoadingPage'
import {setUserToken} from '../reduxStore/UserReducer'
import {
  setDirections,
  setSideMenuMode,
  setMainTheme,
  setSideMenuTheme,
  setToolbarTheme,
  setGridTheme,
  setSearchButtonTheme,
  setAllThemeSettings,
  setForceReload,
  setListOfTranslateData,
  setListOfTranslateDataCode,
  setReduxLoading
} from '../reduxStore/SettingsReducer'
import NetworkDetector from '../../Hoc/NetworkDetector'
import backend from 'i18next-http-backend';
import Host from "../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import FsBackend from "i18next-fs-backend";
import { withSSR,useSSR  } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}



const useStyles = styled(Box)(({theme}) => ({
    backgroundColor: ' #fff !important',
}))



 const SubAppBarAndContainer=(props)=>{
  const [t, i18n] = useTranslation('common');

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const navigate=useNavigate()

  useEffect(() => {
    function handleResize() {
      // console.log(getWindowDimensions())
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const props1 = {
    height: windowDimensions.height
  }
  // const classes = useStyles(props1);


  var timer

  
  const renderPage = (props) => {
    let roles = []

    try {
      
      roles = cookies.get("roles")
      roles.push(4000)//profile id ^-^.
      // roles=roles?.map(role=>role?.id)
    } catch (error) {
     
      
    } 
    let page = null
     pagesLinks.map((pageLink)=>{
      
      if(window.location.pathname=== pageLink?.to && roles?.includes(pageLink?.id))
      {
        console.log('pageLink?.id',pageLink?.id)
        page =  pageLink?.component
      }
      else if(window.location.pathname=== pageLink?.to )
      {
        page = <ErrorPage Code="401" ErrorMessage={t('ERROR_PAGE.ERROR_TITLE_401_ERROR')} title={t('ERROR_PAGE.ERROR_TITLE_401')}RedirectPage="/Dashboard" />//TODO  not authorized page
      }
      

    })
    if(!page)
    {
        page = <ErrorPage Code="404" ErrorMessage={t('ERROR_PAGE.ERROR_TITLE_404_ERROR')} title={t('ERROR_PAGE.ERROR_TITLE_404')}RedirectPage="/Dashboard" />//TODO  not found page
      
    }
    return page
    

  }



  useEffect(() => {

    return () => {
      clearTimeout(timer);
    }
  }, [])




  return (


    <Context.Consumer>{ctx => {


      if (ctx.value.che === "notlogin") {
        navigate('/')
        // return (
        //   <Navigate replace  to="/"></Navigate>
        // )
      } else if (ctx.value.che === "login") {
        return (
          <useStyles>
           
           

            <SideNavBar renderPage={renderPage} propsValue={props}/>
           

          </useStyles>

        )
      } else if (ctx.value.che === "") {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}  >

           
          </div>
        )
      }

    }}

    </Context.Consumer>



  );

}

const TransformFunction__=({initialLanguage,initialI18nStore})=>{
  const dispatch=useDispatch();
  const forceReload=useSelector(state=> state.settingsData.forceReload)
  const ListOfTranslateData=useSelector(state=> state.settingsData.ListOfTranslateData)
  const ListOfTranslateDataCode=useSelector(state=> state.settingsData.ListOfTranslateDataCode)

  const Translate_en = useSelector(state=> state.translateData.translation_en)
  const Translate_ar = useSelector(state=> state.translateData.translation_ar)

  const directions=useSelector(state=> state.settingsData.directions)
  const [loading,setLoading]=useState(false)
  console.log("ListOfTranslateDataCode",ListOfTranslateDataCode);
  console.log("ListOfTranslateData",ListOfTranslateData);
  const [t] = useTranslation('common');

const data=useMemo(()=>{
  // setLoading(true)
  return i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: ListOfTranslateDataCode?ListOfTranslateDataCode:'ar',                              // language to use
    resources: {
       [ListOfTranslateDataCode]:{
        common:ListOfTranslateData
       }
    },
  });
},[ListOfTranslateDataCode,ListOfTranslateData])


  
useEffect(()=>{
  i18next.on('languageChanged', () => {
    setLoading(false)
  })
  setTimeout(()=>{
    if(loading){
      // toast.error('cant loaded all language')
      setLoading(false)
    }
  },300000)
},[])
    

   return(
    <I18nextProvider i18n={i18next}>
        {!loading?
          <SubAppBarAndContainer/>
        :
          <LoadingPage/>
        }
    </I18nextProvider>
   )
}

const AppBarAndContainer=()=>{
  const [loading,setLoading]=useState(true)
  const directions = useSelector(state=> state.settingsData.directions)

  const MainTheme_Store= useSelector(state=> state.settingsData.MainTheme)
  const SideMenuTheme_Store= useSelector(state=> state.settingsData.SideMenuTheme)
  const ToolbarTheme_store= useSelector(state=> state.settingsData.ToolbarTheme)
  const GridTheme_store= useSelector(state=> state.settingsData.GridTheme)
  const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

  const allThemesValue=useSelector(state=>state.settingsData);
  const [translationSectionsData,setTranslationSectionsData]=useState([])
  const [translationCode,setTranslationCode]=useState('')
  const ListOfTranslateDataCode=useSelector(state=> state.settingsData.ListOfTranslateDataCode)
  const forceReload = useSelector(state=> state.settingsData.forceReload);
  const forceReloadTheme=useSelector(state=> state.settingsData.forceReloadTheme);
  const Translate_en = useSelector(state=> state.translateData.translation_en)
  const Translate_ar = useSelector(state=> state.translateData.translation_ar)

  const [language_id,setLanguage_id]=useState(null)
  const dispatch=useDispatch();
  const navigate = useNavigate()
  const token =cookies.get('token');
  const ReduxToken=useSelector(state=>state.userData.token);
  useEffect(()=>{
    if(token){
      dispatch(setUserToken(token))
    }else{
      navigate('/')
    }
  },[token])
   

  const getAllLanguages = async () => {
   
    try {
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "languages_list",
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
                if(!localStorage.getItem('code')&&!localStorage.getItem('direction') ){
                  if(resourcesResult?.data&&resourcesResult?.data?.length>0){
                    localStorage.setItem('code',resourcesResult?.data[0]?.code)
                    localStorage.setItem('direction',resourcesResult?.data[0]?.rtl)
                    dispatch(setDirections(resourcesResult?.data[0]?.rtl==1?'rtl':'ltr'))
                    localStorage.setItem('language_id',resourcesResult?.data[0]?.id)
                    getAllTranslationSections(resourcesResult?.data[0]?.id,resourcesResult?.data[0]?.code)
                    dispatch(setForceReload(false))
               
                  }
                }else{
                    dispatch(setDirections(localStorage.getItem('direction')==1?'rtl':'ltr'))
                     getAllTranslationSections(parseInt(localStorage.getItem('language_id')),localStorage.getItem('code'))
                     dispatch(setForceReload(false))


                }
            } else if (resourcesResult.data.status === false) {
              setLoading(false)
            }
        }
        else {
            toast.error("Error")
            setLoading(false)
        }
        // setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

  };
  const getAllTranslationSections= async (id,code) => {
   console.log('language_id',id,code);
    try {
        // setLoading(true)
        const resourcesResult = await axios({
            url: Host + "translation_sections_list?language_id="+id,
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
              setTranslationSectionsData(resourcesResult?.data)
              setTranslationCode(code)
              dispatch(setListOfTranslateDataCode(code))
              dispatch(setListOfTranslateData(resourcesResult?.data))
              dispatch(setReduxLoading(false))


              setLoading(false)
            } else if (resourcesResult.data.status === false) {
              setLoading(false)
            }
        }
        else {
            toast.error("Error")
            setLoading(false)
        }
        // setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

  };
  const getAllThemeSettings= async () => {

    try {
        // setLoading(true)
        const resourcesResult = await axios({
            url: Host + "theme_sections_list",
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
              if(resourcesResult?.data){
                dispatch(setMainTheme({
                  ...MainTheme_Store,
                  ...resourcesResult?.data?.MainTheme
                }))
                dispatch(setSideMenuTheme({
                  ...SideMenuTheme_Store,
                  ...resourcesResult?.data?.SideMenuTheme
                }))
                dispatch(setToolbarTheme({
                  ...ToolbarTheme_store,
                  ...resourcesResult?.data?.ToolbarTheme,
                }))
                dispatch(setGridTheme({
                  ...GridTheme_store,
                  ...resourcesResult?.data?.GridTheme
                }))
                dispatch(setSearchButtonTheme({
                  ...SearchButtonTheme,
                  ...resourcesResult?.data?.SearchButtonTheme
                }))
                dispatch(setReduxLoading(false))

              }
                // dispatch(setAllThemeSettings({
                //   ...allThemesValue,
                //   ...resourcesResult?.data
                // }))
                
            } else if (resourcesResult.data.status === false) {
            }
            setLoading(false)
        }
        else {
            toast.error("Error")
            setLoading(false)

        }
        // setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

  };

  useEffect(()=>{
 
    if(token){
      if(forceReload){
        getAllLanguages()
      }
      else if((localStorage.getItem('code')&&localStorage.getItem('code')!=translationCode)||!translationSectionsData){
        getAllLanguages()
      }else if(!localStorage.getItem('code')){
        getAllLanguages()
      }
    }
  },[directions,ListOfTranslateDataCode,forceReload])

  useEffect(()=>{
    getAllThemeSettings()
  },[forceReloadTheme])





  if((translationSectionsData&&translationCode)){
    return(
      <TransformFunction__   initialLanguage={translationCode} initialI18nStore={translationSectionsData}/>
    )
  }
  else {
    return <LoadingPage/>
  }

}



export default NetworkDetector(AppBarAndContainer)


