import React,{useState,useEffect,useCallback} from 'react';
import {Box, Typography} from '@mui/material'
import {useSelector,useDispatch} from 'react-redux'
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Size,
} from 'devextreme-react/pie-chart';
import { position } from 'stylis';


import CenterTemplate from './CenterTemplate';



const data = [
    { country: 'France', commodity: 'January', total: 4000000 },
    { country: 'France', commodity: 'February', total: 5000000 },
    { country: 'France', commodity: 'March', total: 6000000 },
    { country: 'France', commodity: 'April', total: 4000000 },
  ];

  

const DoughnutChart2 = (props)=> {
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)
    const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
    const [forceReloadChart,setForceReloadChart]=useState(false)
    const directions = useSelector(state=> state.settingsData.directions)

    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

    const customizeLabel = (e)=> {
      return `${e.argumentText}\n\n${e.valueText}`;
    }

    const [dataSource,setDataSource]=useState([])

    useEffect(()=>{
        var arr=[];
        var TotalRoadTimePercentage=0;
        var TotalVisitTimePercentage=0;
        var totalOfTowPercentage=0

       if(props?.productivityData){
          props?.productivityData?.map((itm)=>{
            TotalRoadTimePercentage+=itm?.road_time_percentage
            TotalVisitTimePercentage+=itm?.visit_time_percentage
          })
          totalOfTowPercentage=TotalRoadTimePercentage+TotalVisitTimePercentage;
          console.log('asdasdsada--1',TotalRoadTimePercentage)
          console.log('asdasdsada--2',TotalVisitTimePercentage)
          console.log('asdasdsada--3',totalOfTowPercentage)

          TotalRoadTimePercentage=Math.round((((TotalRoadTimePercentage)/totalOfTowPercentage)*100));
          TotalVisitTimePercentage=Math.round((((TotalVisitTimePercentage)/totalOfTowPercentage)*100));
       }
       
       arr=[{
        name: props?.boxTitle_2, area: TotalRoadTimePercentage/100,

       },{
        name: props?.boxTitle_1, area: TotalVisitTimePercentage/100,
       }]

       setDataSource(arr)
    },[props?.productivityData])


   const CustomizePiChartColor=(pointInfo)=>{
    console.log('asdasdasdasdasdsadsadsa=>',pointInfo)
    if (pointInfo.argument=='النسبة المئوية الإجمالية لوقت الزيارة'||pointInfo.argument== "Total Visit Time Percentage") {
        return {
          color:(SearchButtonTheme&&SearchButtonTheme?.visit_time_color)?SearchButtonTheme?.visit_time_color:"#FF0000"
        }
    }
    return {
      color:(SearchButtonTheme&&SearchButtonTheme?.road_time_color)?SearchButtonTheme?.road_time_color:"#2188bf",
    }
}



    return (
      <Box
          sx={{
            width:props?.customeWidth,
            height:'auto',
            // backgroundColor:MainTheme?.primaryColor+'22',
            color:MainTheme?.oppositePrimaryColor+' !important',
            // boxShadow:`0 0 7px 1px ${MainTheme?.MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}`,
            borderRadius:'15px',
            padding:'15px 0px',
            margin:'10px 0px',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'start',
            alignItems: 'start',
            // boxShadow: `3px 11px 10px -7px ${MainTheme?.paperTextColor}22;`,
            position:'relative',
          }}   
      >
        <Box
            sx={{
              display:'flex',
              flexDirection:'column',
              width:'100%',
              justifyContent:'start',
              alignItems:'start',
              '& .subTitle':{
                  color:MainTheme.oppositePrimaryColor+'bb',
                  fontSize:'15px',
                  position:'relative',
                  top:'-5px',
                  wordWrap:' break-word',
                  whiteSpace:'break-spaces',
                  display:'flex !important',
                  justifyContent:'start !important',
                  width:'90%',
                  textAlign: 'left',
            
              },
              '& h5':{
                fontSize:'21px',
                display:'flex',
                justifyContent:'start',
              }

            }}
          > 
          <h5>{props?.title+'  "'+props?.userName+' " '}</h5>
          <Typography className="subTitle">{props?.subTitle}</Typography>
        </Box>
        <Box
         sx={{
          width:'100%',
          display:'flex !important',
          justifyContent:'center !important',
          alignItems: 'center',
         }}
        >
          <PieChart
            className="pie"
            sizeGroup="piesGroup"
            dataSource={dataSource}
            customizePoint={CustomizePiChartColor}
          >
            <Size
                  height={300}
                  width={300}
            />
            <Series argumentField="name" valueField="area">
              <Label visible={true} format="percent" />
            </Series>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
              rowCount={2}
            />
          </PieChart>
        </Box>
      </Box>
    );
  
}

export default DoughnutChart2;



